import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';
import { Box, Button, Card, CardContent, CardMedia, Chip, Grid, Stack, styled, Tooltip, Typography } from '@mui/material';
import noCampaign from '../../img/Collabrate/Group.png'
import { useHistory } from 'react-router-dom';
import { default_limit } from '../../_services/_constants/recordLimitset.constant';
import ApiService from '../../_services/ApiService';
import SkeletonCard from '../../_helpers/reusablefunctions/SkeletonCard';
import playlist from '../../img/Frame 48096170.png'
import GetMoodGenreLanguage from '../../_helpers/reusablefunctions/getMoodGenreLanguage';
import Pagination from '../../_helpers/reusablefunctions/Pagination';
import SearchBox from '../../components/reusableComponents/SearchBox';

const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: '10px',
    color: '#545454',
    background: '#fff',
    border: '0px',
    padding: '6px 20px 6px 20px',
    fontSize: '14px',
    '&:hover': {
        backgroundColor: '#ED1C24',
        color: '#fff',
    },
    '&.MuiChip-clickable:focus, &.MuiChip-clickable:hover': {
        backgroundColor: '#ED1C24',
        color: '#fff',
    },
    '&.MuiChip-clickable': {
        '&.Mui-active': {
            backgroundColor: '#ED1C24',
            color: '#fff',
        },
    },
}));
const campaignList = [
    { label: 'song_promotion', value: ["Artist Community"] },
    { label: 'reelcampaign', value: ["Creator Campaign"] },
]

export default function Campaign() {
    let history = useHistory();
    const userData = useSelector((state) => state.userData.userData);
    const [activeChip, setActiveChip] = useState('0-0');
    const [activeLabel, setActiveLabel] = useState('song_promotion');
    const [campaignDetails, setCampaignDetails] = useState([]);
    const [loader, setLoader] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(default_limit);
    const [totalCount, setTotalCount] = useState()
    const [searchInput, setSearchInput] = useState('')

    // -------------API Calls song promotion & campaign

    function songPromotionApiCall(url) {
        setLoader(true)
        setCampaignDetails([])
        ApiService.get('songPromotionCampaign', { search: userData?.id, limit: 25, offset: page }, url).then(res => {
            const response = res?.data || []
            setLoader(false)
            if (response) {
                setCampaignDetails(response?.results)
                setTotalCount(response?.total_count)
            }
        }).catch(function (error) {
            setLoader(false)
            console.log(error);
            console.log(error.response);
        });
    }

    // ---------------- Search Api Call ---------------

    function getSearchList(url) {
        setLoader(true)
        setCampaignDetails([])
        ApiService.get('songPromotionCampaign', { search: userData?.id, song_name: searchInput, limit: 25, offset: page }, url).then(res => {
            const response = res?.data || []
            setLoader(false)
            if (response) {
                setCampaignDetails(response?.results)
                setTotalCount(response?.total_count)
            }
        }).catch(function (error) {
            setLoader(false)
            console.log(error);
            console.log(error.response);
        });
    }

    useEffect(() => {
        if (searchInput != null && searchInput.length > 0) {
            let delaySearch;
            delaySearch = setTimeout(() => {
                getSearchList(activeLabel + '/user')
            }, 1000);
            return () => clearTimeout(delaySearch)
        } else {
            if (userData?.id) {
                songPromotionApiCall(activeLabel + '/user')
            }
        }
    }, [userData, page, rowsPerPage, searchInput])


    const handleChipClick = (i, label) => {
        setActiveChip(i)
        setActiveLabel(label)
        songPromotionApiCall(label + '/user')
    }

    function openCreateCampaign(e) {
        e.preventDefault();
        history.push('/campaign/create')
    }

    // --------------Pagination 

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 25);
        const newPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setPage(newPage);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // -----------search campaign and promotion
    const handleSearchInput = (value) => {
        setSearchInput(value)
    }

    // -----------------campaign view details

    function openCampaignDetail(e, data) {
        e.preventDefault();
        history.push({ pathname: '/campaign/view', state: data })
    }

    // --------card dynamic render

    const cardPromotio = (data) => {
        return (
            <Card>
                <Box position="relative" width="100%" height="160px" borderRadius={'8px'} overflow={'hidden'}>
                    <CardMedia
                        sx={{ height: '100%' }}
                        image={data.songPoster_url ?
                            data?.songPoster_url
                            : playlist}
                        title=""
                    // style={{ cursor: 'pointer' }}
                    />
                    <Chip className='chipStyle' label={data?.status} />
                </Box>

                <CardContent>
                    <Typography variant="body2" component={'div'} className='d-flex align-items-baseline gap-2' sx={{ color: 'text.secondary', margin: '8px 0px' }}>
                        <Typography variant="subtitle2" gutterBottom>Number Of Artists:</Typography> {data?.number_of_artists || '--'}
                    </Typography>
                    <Typography variant="body2" component={'div'} className='d-flex align-items-baseline gap-2' sx={{ color: 'text.secondary', margin: '8px 0px' }}>
                        <Typography variant="subtitle2" gutterBottom>Per Person Budget:</Typography> {parseFloat(data?.per_person_budget)?.toFixed(1) + 'Rs/-' || '--'}
                    </Typography>
                    <Typography variant="body2" component={'div'} className='d-flex align-items-baseline gap-2' sx={{ color: 'text.secondary', margin: '8px 0px' }}>
                        <Typography variant="subtitle2" gutterBottom>Budget Amount:</Typography> {parseFloat(data?.budget_amount)?.toFixed(1) + 'Rs/-' || '--'}
                    </Typography>
                    <GetMoodGenreLanguage label="Platform" items={data?.platforms || []} />
                </CardContent>
            </Card>
        )
    }

    const cardCampaign = (data) => {
        return (
            <Card onClick={(e) => openCampaignDetail(e, data)}>
                <Box position="relative" width="100%" height="160px" borderRadius={'8px'} overflow={'hidden'}>
                    <CardMedia
                        sx={{ height: '100%' }}
                        image={data.songPoster_url ? data?.songPoster_url
                            : playlist}
                        title=""
                        style={{ cursor: 'pointer' }}
                    />
                    <Chip className='chipStyle' label={data?.status} />
                </Box>

                <CardContent>
                    <Typography variant="body2" component={'div'} className='d-flex align-items-baseline gap-2' sx={{ color: 'text.secondary', margin: '8px 0px' }}>
                        <Typography variant="subtitle2" gutterBottom>Song Name:</Typography> {data?.songName || '--'}
                    </Typography>
                    <Typography variant="body2" component={'div'} className='d-flex align-items-baseline gap-2' sx={{ color: 'text.secondary', margin: '8px 0px' }}>
                        <Typography variant="subtitle2" gutterBottom>Campaign Name:</Typography> {data?.campaignName || '--'}
                    </Typography>
                    {/* <GetMoodGenreLanguage label="Platform" items={data?.platform || []} /> */}
                </CardContent>
            </Card>
        )
    }


    return (
        <Box className='container-fluid py-4 px-4 creator-playlist-panel'>
            <Box className='d-flex justify-content-between'>
                <h2>Campaign</h2>
                <Button variant='contained' className='gn-actionbtn' onClick={(e) => openCreateCampaign(e)}>
                    {/* <Button variant='contained' className='gn-actionbtn' onClick={() => { setOpenCampaignModal(true) }}> */}
                    Create Campaign Now!
                    {/* Create Your First Campaign Now! */}
                </Button>
            </Box>
            <Box className="d-flex align-items-center justify-content-between my-4 flex-wrap gap-2">
                <Stack direction="row" spacing={1}>
                    {campaignList.map((d, i) =>
                        d.value.map((v, index) => (
                            <StyledChip
                                key={`${i}-${index}`}
                                label={v}
                                component="a"
                                variant="outlined"
                                clickable
                                onClick={() => handleChipClick(`${i}-${index}`, d.label, v)}
                                className={activeChip === `${i}-${index}` ? 'Mui-active' : ''}
                            />
                        ))
                    )}
                </Stack>
                <SearchBox handleSearchInput={handleSearchInput} />
            </Box>
            <Box className='my-4'>
                <Grid container spacing={2}>
                    {loader ?
                        Array.from(new Array(8)).map((item, index) => (<Grid key={index} item xs={12} sm={6} md={3} lg={3} >
                            <SkeletonCard />
                        </Grid>))
                        :
                        loader === false && campaignDetails.length === 0 ? (
                            <Box className='d-flex align-items-center flex-column gap-2 w-100'>
                                <img src={noCampaign} alt='no campaign' />
                                <Typography variant='h5' >No Campaigns Yet!</Typography>
                                <Typography variant='body1' >It looks like you haven’t started a campaign. Don’t miss out on promoting your content to the right audience!</Typography>
                            </Box>
                        )
                            :
                            campaignDetails.map((data, index) => (<Grid key={index} item xs={12} sm={6} md={3} lg={3} >
                                {activeLabel == "song_promotion" ? cardPromotio(data) : cardCampaign(data)}
                            </Grid>))}

                </Grid>
                <Pagination
                    totalCount={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Box>

        </Box >
    );
}
