import React, { useEffect, useRef, useState } from "react";
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Button, IconButton, Typography, Grid,
  Box
} from "@mui/material";
import useFirebaseAuthentication from "../../_helpers/reusablefunctions/firebaseLoginFunctions";
import PhoneInput from 'react-phone-input-2';
import { auth } from "../../firebase";
import { EmailAuthProvider, linkWithCredential, PhoneAuthProvider, sendEmailVerification } from "firebase/auth";
import { fetchUserDetail, setLoader } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../_services/profile";
import { errorLogin } from "../../_helpers/reusablefunctions/errorLogin";

const PhoneVerificationDialog = () => {
  const userData = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();
  const userAuthDetail = auth?.currentUser;
  const loginMethod = userAuthDetail?.providerData[0]?.providerId == "phone" ? 'phone number' : 'email'
  const [phoneNumber, setPhoneNumber] = useState("");
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1); // Step 1: Enter phone, Step 2: Enter OTP
  const [timer, setTimer] = useState(30);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState(true);
  const inputRefs = useRef([]);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [reCAPTCHAToken, setReCAPTCHAToken] = useState(null);
  const [otpCode, setOtpCode] = useState(Array(6).fill(""));
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState('');
  const { sendOTP } = useFirebaseAuthentication({
    setConfirmationResult,
    confirmationResult,
    setReCAPTCHAToken,
    setErrorMsg,
    from: {},
    phoneNumber,
    otpCode,
    setTimer
  });
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  useEffect(() => {
    const interval = setInterval(async () => {
      await userAuthDetail?.reload();
      if (auth?.currentUser?.emailVerified) {
        clearInterval(interval);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [userAuthDetail]);


  useEffect(() => {
    const isPhoneNumberInvalid = !userAuthDetail?.phoneNumber;
    const isEmailNotVerified = !userAuthDetail?.emailVerified;
    if (userAuthDetail && (isEmailNotVerified || isPhoneNumberInvalid)) {
      setOpen(true);
      setPhoneNumber(userAuthDetail?.phoneNumber)
      setEmail(userAuthDetail?.email)
    }
  }, [userAuthDetail]);

  // Handle Phone Input Change
  const handlePhoneChange = (phone) => {
    setPhoneNumber(phone)
    phone.length != 12 ? setError(true) : setError(false)
  };

  // Handle OTP Input Change
  const handleChange = (index, value) => {
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otpCode];
      newOtp[index] = value;
      setOtpCode(newOtp);
      // Move to the next input field
      if (value && index < 6 - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  // Start Resend Timer

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setIsResendDisabled(false);
    }
  }, [timer]);

  // ------------------- update email and phone with user 

  const updateEmailPhone = async () => {
    let dataObj = {}
    if (email) {
      dataObj.email = email
    }
    if (phoneNumber) {
      dataObj.contactNumber = phoneNumber
    }
    updateProfile(dataObj, userData?.id).then(function (response) {
      if (response.status === 200) {
        dispatch(fetchUserDetail(userData))
      }
      dispatch(setLoader(false))
    })
  }

  // Verify OTP
  const verifyOtp = async () => {
    try {
      if (!confirmationResult) {
        console.error('No confirmation result found.');
        return;
      }

      const phoneCredential = PhoneAuthProvider.credential(confirmationResult.verificationId, otpCode.join(""));

      // Link phone number to existing user
      await linkWithCredential(userAuthDetail, phoneCredential);
      console.log("Phone number linked successfully to user: " + userAuthDetail.uid);
      setEmailSent("Phone number linked successfully.")
      await updateEmailPhone();
    } catch (otpError) {
      console.error('Error verifying OTP:', otpError.message);
      setErrorMsg(otpError.message)
    }
  };

  // ----------------------- email verification links---------------

  const linkEmailWithPhone = async () => {
    if (!email) {
      setErrorMsg("Email is required.");
      return;
    } else if (!validateEmail(email)) {
      setErrorMsg("Invalid email format.");
      return;
    }
    setErrorMsg('')
    try {
      if (!userAuthDetail) {
        setErrorMsg("No authenticated user found.");
        return;
      }

      // Create email credentials
      const credential = EmailAuthProvider.credential(email, window.location.href);

      // Link email with phone-based account
      await linkWithCredential(userAuthDetail, credential);
      console.log("Email linked successfully with UID:", userAuthDetail.uid);

      // Send verification email
      await sendEmailVerification(userAuthDetail);
      console.log("Verification email sent to:", userAuthDetail.email);
      setEmailSent("Verification email sent to:", email, " Please check your inbox.")
      await updateEmailPhone();
    } catch (error) {
      console.error("Error linking email:", error.message);
      let errorMessage = errorLogin(error)

      setErrorMsg(errorMessage);
    }
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      {/* Header */}
      {emailSent == '' && <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        Verify Your Account
      </DialogTitle>}
      {/* Content */}
      <DialogContent>
        {emailSent == '' && <Typography variant="body2">
          We need to verify your {loginMethod == "email" ? "phone number" : "email"} since you signed up with an {loginMethod}.
        </Typography>}

        {/* Step 1: Enter Phone Number */}
        {/* {!confirmationResult && ( */}
        {emailSent == '' ? (loginMethod != "email" ?
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
            size="small"
            error={error}
            helperText={errorMsg}
          />
          :
          <>
            <Box id="recaptcha-container"></Box>
            <Box mt={2}>
              <PhoneInput
                country={'in'}
                value={phoneNumber}
                name="phone_number"
                onChange={handlePhoneChange}
                specialLabel="Enter phone number"
                disabled={confirmationResult}
                style={{ opacity: confirmationResult ? 0.5 : 1 }}
              />
            </Box>
          </>)
          : (
            <Typography variant="body1" color="success.main">
              {emailSent}
            </Typography>
          )}

        {/* Step 2: Enter OTP */}
        {confirmationResult && emailSent == '' && (
          <Box mt={2}>
            <Grid container gap={2}>
              {otpCode.map((digit, index) => (
                <TextField
                  key={index}
                  value={digit}
                  onChange={(e) => handleChange(index, e.target.value)}
                  inputRef={(ref) => (inputRefs.current[index] = ref)}
                  variant="outlined"
                  size="small"
                  inputProps={{
                    maxLength: 1,
                    style: {
                      textAlign: "center",
                      fontSize: "14px",
                      width: "20px",
                      height: "30px",
                    },
                  }}
                />
              ))}
              {errorMsg}
            </Grid>
            <Button variant='text' className="gn-actionbtn-color"
              onClick={sendOTP}
              disabled={isResendDisabled}>
              Resend OTP {timer > 0 ? `(${timer}s)` : ""}
            </Button>
          </Box>
        )}
      </DialogContent>
      {/* Actions */}
      <DialogActions sx={{ justifyContent: "end", padding: "16px" }}>
        {emailSent == '' ? (loginMethod == "email" ?
          <>
            {!confirmationResult ? (
              <Button variant="contained"
                className='gn-actionbtn px-3 ms-3'
                onClick={sendOTP} disabled={error}>
                Send Verification Code
              </Button>
            ) : (
              <Button variant="contained"
                className='gn-actionbtn px-3 ms-3'
                onClick={verifyOtp} disabled={otpCode.join("").length < 6}>
                Verify OTP
              </Button>
            )}
          </>
          :
          <Button variant="contained"
            className='gn-actionbtn px-3 ms-3'
            onClick={linkEmailWithPhone}
          //  disabled={email == "" ? true : false}
          >
            Verify Email
          </Button>

        ) : (
          <Button
            variant="contained"
            className="gn-actionbtn px-3 ms-3"
            onClick={() => setOpen(false)}
            disabled={!userAuthDetail?.emailVerified}
          >
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PhoneVerificationDialog;
