import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Card, CardContent, IconButton } from '@mui/material';
import { useLocation } from 'react-router-dom';
const PaymentSuccess = () => {
    const location = useLocation()
    return (
        <Box className='d-flex align-items-center justify-content-center vh-100' >
            <Card sx={{ minWidth: 400, maxWidth: 400, height: 350 }}>
                <CardContent sx={{ textAlign: 'center' }}>
                    <IconButton  >
                        <CheckCircleIcon color="success" sx={{ height: 60, width: 60 }} />
                    </IconButton>
                    <h2>Your payment was successful </h2>
                    <p>You will be redirected to the home page shortly or click here to return to home page.</p>
                    <a href="/campaign">
                        <Button variant="contained"
                            className='gn-actionbtn px-3 ms-3 mt-3 fs-6'>
                            Go to Home
                        </Button>
                    </a>
                </CardContent>
            </Card>
        </Box>
    )
}

export default PaymentSuccess;