import * as React from 'react';
import { Box, Card, CardContent, TableContainer, Grid, List, ListItem, Table, TableBody, TableCell, TableRow, TableHead, Typography, Paper } from '@mui/material';
import logoGntunes from "../../img/logo4.svg";
import { CheckCircle, Cancel } from "@mui/icons-material";
import { useEffect } from 'react';
import ApiService from '../../_services/ApiService';
import { useState } from 'react';

export default function BudgetPlan({ handleNextStates, setActivePlan, activePlan, setPackageAmount }) {

    const [packageList, setPackageList] = useState([])

    const planList = [{ bg: "linear-gradient(180deg, rgba(221, 170, 170, 0.8) 0%, #804A00 100%)", head: "Bronze" },
    { bg: "linear-gradient(180deg, rgba(237, 237, 237, 0.266667) 0%, #BDBDBD 55%)", head: "Silver" },
    { bg: "linear-gradient(180deg, rgba(241, 247, 251, 0.77) 0%, rgba(203, 227, 240, 0.9) 52.4%, #9AC5DB 100%)", head: "Diamond" },
    { bg: "linear-gradient(180deg, rgba(247, 239, 138, 0.77) 0%, rgba(174, 134, 37, 0.9) 52.4%, #D2AC47 100%)", head: "Gold" },
    ]

    const data = [
        { feature: "Pages", bronze: true, silver: true, gold: true, diamond: false },
        { feature: "Creator", bronze: true, silver: true, gold: true, diamond: false },
        { feature: "Influncer", bronze: true, silver: true, gold: true, diamond: false },
    ];

    useEffect(() => {
        ApiService.get('getPackage').then(res => {
            if (res?.data?.data) {
                setPackageList(res?.data?.data)
            }
        })
            .catch(error => {
                console.log(error)
            })
    }, [])

    // ----- next click states send 

    useEffect(() => {
        if (activePlan) {
            handleNextStates(activePlan)
        }
    }, [activePlan])

    return (
        <div>
            <Grid container spacing={2} alignItems='stretch'>
                {packageList.map((data, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card key={index} onClick={() => { setActivePlan(data.id); setPackageAmount(data) }} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 2, background: index === 0 ? planList[0].bg :
                                index === 1 ? planList[1].bg :
                                    index === 2 ? planList[2].bg :
                                        index === 3 ? planList[3].bg :
                                            null, color: 'white',
                            cursor: 'pointer',
                            height: '100%',
                            border: activePlan == data?.id ? '2px solid #E74C3C' : '',
                        }}>
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Box className='w-25 mb-3'>
                                    <img className='w-100' src={logoGntunes} alt='gntunes-logo' />
                                </Box>
                                <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', margin: '0px' }} color="text.secondary" gutterBottom>
                                    {data?.PackageType}
                                </Typography>
                                {/* <Typography variant="body2" sx={{ fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                                    Reel & Shorts 1500
                                </Typography> */}

                                {/* Influncer & Creator */}
                                <Typography variant="body2" className='bg-body rounded-pill text-black px-2 py-1 mt-3'>Creators & Influencers </Typography>
                                <List>
                                    {data?.Package_Creator.map((page, index) => (
                                        <ListItem sx={{ padding: '4px 0px' }} key={index}>
                                            <Typography variant="body2">{page?.Creator}</Typography>
                                            <Typography variant="body2" sx={{ marginLeft: 'auto' }}>
                                                {page?.No_of_Creator}
                                            </Typography>
                                        </ListItem>
                                    ))}
                                    {data?.Package_Influencer.map((page, index) => (
                                        <ListItem sx={{ padding: '4px 0px' }} key={index}>
                                            <Typography variant="body2">{page?.Influencer}</Typography>
                                            <Typography variant="body2" sx={{ marginLeft: 'auto' }}>
                                                {page?.No_of_Influencer}
                                            </Typography>
                                        </ListItem>
                                    ))}
                                </List>

                                {/* Pages  */}
                                <Typography variant="body2" className='bg-body rounded-pill text-black px-2 py-1'>Pages</Typography>
                                <List>
                                    {data?.Package_Pages.map((page, index) => (
                                        <ListItem sx={{ padding: '5px 0px' }} key={index}>
                                            <Typography variant="body2">{page?.Page}</Typography>
                                            <Typography variant="body2" sx={{ marginLeft: 'auto' }}>
                                                {page?.No_of_Pages}
                                            </Typography>
                                        </ListItem>
                                    ))}
                                </List>
                            </CardContent>

                            <Box sx={{ padding: 1, textAlign: 'center', backgroundColor: '#E74C3C', margin: '0px 15px 10px 15px', borderRadius: '10px' }}>
                                <Typography variant="body2">{'₹ ' + data?.PackageAmount}</Typography>
                            </Box>
                        </Card>
                    </Grid>
                ))}

            </Grid>
            <Box my={3} textAlign={'center'}>
                <Typography variant='h5'>Discover feature & compare plan</Typography>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    {/* Table Header */}
                    <TableHead>
                        <TableRow>
                            <TableCell>Availability</TableCell>
                            <TableCell align="center">Bronze</TableCell>
                            <TableCell align="center">Silver</TableCell>
                            <TableCell align="center">Gold</TableCell>
                            <TableCell align="center">Diamond</TableCell>
                        </TableRow>
                    </TableHead>
                    {/* Table Body */}
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    backgroundColor: "#f0f0f0",
                                    borderRadius: "8px",
                                    "&:hover": { backgroundColor: "#eaeaea" },
                                }}
                            >
                                <TableCell>{row.feature}</TableCell>
                                <TableCell align="center">
                                    {row.bronze ? (
                                        <CheckCircle style={{ color: "limegreen" }} />
                                    ) : (
                                        <Cancel style={{ color: "red" }} />
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    {row.silver ? (
                                        <CheckCircle style={{ color: "limegreen" }} />
                                    ) : (
                                        <Cancel style={{ color: "red" }} />
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    {row.gold ? (
                                        <CheckCircle style={{ color: "limegreen" }} />
                                    ) : (
                                        <Cancel style={{ color: "red" }} />
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    {row.diamond ? (
                                        <CheckCircle style={{ color: "limegreen" }} />
                                    ) : (
                                        <Cancel style={{ color: "red" }} />
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </div >
    );
}

