import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, Stack, Typography, Tooltip, IconButton, Tab } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import PlaylistPlatforms from "./playlistPlatforms";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PlaylistAddSong from './PlaylistAddSong';
import DeleteIcon from '@mui/icons-material/Delete';
import ApiService from '../../../../_services/ApiService';
import playlist from '../../../../img/Frame 48096170.png'

export default function OfficialPlaylistDetail() {
    const location = useLocation();
    const history = useHistory();
    const ref = useRef();
    const getPlaylistData = location?.state?.playlistData
    const [playlistData, setPlaylistData] = useState([]);
    const [loader, setLoader] = useState(true)
    const [tabValue, setTabValue] = useState(getPlaylistData?.type_official &&
        (getPlaylistData?.type_trending || getPlaylistData?.type_trending === null || getPlaylistData?.type_trending === "") ? '1' : '2')
    const [playlistSongLibrary, setPlaylistSongLibrary] = useState([])

    const accountTabs = [
        { id: 1, tabActiveId: "1", tabTitle: 'Song', tabType: 'song' },
        { id: 2, tabActiveId: "2", tabTitle: 'Platform', tabType: 'platform' }]
    const handleRouteBack = () => {
        history.push('/discover-playlist')
    }
    function handleTabChange(e, newValue) {
        setTabValue(newValue)
    }
    function getSingleSong() {
        setLoader(true)
        ApiService.get('getSinglePlaylist', { id: getPlaylistData.playlist_id }).then(res => {
            setLoader(false)
            if (res.status === 200 || res.status === 201) {
                setPlaylistData(res.data.data[0].song)
                setPlaylistSongLibrary(res.data.data[0].playlist_releated_platform)
            }
        }).catch(error => {
            console.log(error)
        })
    }
    useEffect(() => {
        getSingleSong()
    }, [])

    return (
        <Box sx={{ padding: '1rem 1rem 3rem 1rem' }}>
            <Box alignItems={'center'} display={'flex'} justifyContent={'space-between'} >
                <Button className='gap-3 text-black' onClick={handleRouteBack}><ArrowBackIcon /> {getPlaylistData?.playlist_name}</Button>
            </Box>
            <Box mt={2} mb={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} lg={3} >
                        <Card className="" role="box" tabIndex="0">
                            <CardMedia
                                component="img"
                                style={{
                                    height: '200px',
                                }}
                                image={getPlaylistData?.playlist_cover_image ? process.env.REACT_APP_BASEURL_MEDIA + '/media/' + getPlaylistData?.playlist_cover_image : playlist}
                            />
                            <CardContent >
                                <Typography variant="body2"><strong>Name:</strong> {getPlaylistData?.playlist_name}</Typography>
                                <Box className='d-flex align-items-center gap-2' >
                                    <Typography variant="body2"><strong>Genre:</strong></Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                                        {getPlaylistData?.playlist_genre.map((genre) => (
                                            <Chip key={genre} label={genre} sx={{ backgroundColor: '#E6EEEF' }} />
                                        ))}
                                    </Box>

                                </Box>
                                <Box className='d-flex align-items-center gap-2' >
                                    <Typography variant="body2"><strong>Mood:</strong></Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                                        {getPlaylistData?.mood.map((mood) => (
                                            <Chip key={mood} label={mood} sx={{ backgroundColor: '#E6EEEF' }} />
                                        ))}
                                    </Box>
                                </Box>
                                <Box className='d-flex align-items-center gap-2' >
                                    <Typography variant="body2"><strong>Language:</strong></Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                                        {getPlaylistData?.language.map((language) => (
                                            <Chip key={language} label={language} sx={{ backgroundColor: '#E6EEEF' }} />
                                        ))}
                                    </Box>
                                </Box>
                            </CardContent >
                        </Card >
                    </Grid>

                    <Grid item xs={12} md={8} lg={9} >
                        <TabContext value={tabValue}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList
                                    scrollButtons="auto"
                                    variant="scrollable"
                                    orientation="horizontal"
                                    scroller='true'
                                    sx={{
                                        '& .Mui-selected': {
                                            color: '#E41116 !important',
                                        },
                                        '& .MuiTabs-indicator': {
                                            backgroundColor: '#E41116',
                                        },
                                    }}
                                    onChange={handleTabChange}
                                    aria-label="lab API tabs example">
                                    {getPlaylistData?.type_official &&
                                        (getPlaylistData?.type_trending || getPlaylistData?.type_trending === null || getPlaylistData?.type_trending === '') ? accountTabs?.map((item, i) => {
                                            return (
                                                <Tab sx={{ fontSize: '13px' }} label={item.tabTitle} key={i} value={item.tabActiveId} />)
                                        }) : accountTabs?.slice(1, 2).map((item, i) => {
                                            return (
                                                <Tab sx={{ fontSize: '13px' }} label={item.tabTitle} key={i} value={item.tabActiveId} />)
                                        })}
                                </TabList>
                            </Box>

                            {(() => {
                                switch (tabValue) {
                                    case '1':
                                        return (<TabPanel className='px-0 pt-2' value='1'>
                                            <PlaylistAddSong loader={loader} playlistData={playlistData} />
                                        </TabPanel>);
                                    case '2':
                                        return (<TabPanel className='px-0 pt-2' value='2'>
                                            {playlistSongLibrary && playlistSongLibrary.map((card, index) => (
                                                card.status ? <Box key={card.id} index={index}>
                                                    <PlaylistPlatforms getSingleSong={getSingleSong}
                                                        playlistSongLibrary={card} setPlaylistSongLibrary={setPlaylistSongLibrary}
                                                        allplaylistSongLibrary={playlistSongLibrary} getPlaylistData={getPlaylistData} />
                                                </Box> : null
                                            ))}

                                        </TabPanel>);
                                }
                            })()}
                        </TabContext>
                    </Grid>

                </Grid>
            </Box>
        </Box >
    )
} 
