import {
  FETCH_USER_TYPE_FAILURE,
  FETCH_USER_TYPE_REQUEST,
  FETCH_USER_TYPE_SUCCESS
  } from './userTypeTypes'
import { getUserType } from '../../_services/userType/getUserType.service'
  
  export const fetchUserTypeList = () => {
    return async (dispatch) => {
      try {
      const response =  await getUserType()
      const userData = response.data.data;
      dispatch(fetchUserTypeSuccess(userData));
      }
      catch (error) {
        dispatch(fetchUserTypeFailure(error.message));
      }
    }
  }
  
  export const fetchUserTypeRequest = () => {
    return {
      type: FETCH_USER_TYPE_REQUEST
    }
  }
  
  export const fetchUserTypeSuccess = UserTypeData => {
    return {
      type: FETCH_USER_TYPE_SUCCESS,
      payload: UserTypeData
    }
  }
  
  export const fetchUserTypeFailure = error => {
    return {
      type: FETCH_USER_TYPE_FAILURE,
      payload: error
    }
  }
  