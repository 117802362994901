import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';
import { Box, Button, Card, CardContent, CardMedia, Chip, CircularProgress, Grid, List, ListItem, ListItemButton, ListItemText, Stack, styled, Tooltip, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import ApiService from '../../_services/ApiService';
import playlist from '../../img/Frame 48096170.png'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter';
import GetMoodGenreLanguage from '../../_helpers/reusablefunctions/getMoodGenreLanguage';
import EditIcon from '@mui/icons-material/Edit';

export default function CampaignDetail() {
    const history = useHistory();
    const location = useLocation();
    const [campaignDetail, setCampaignDetail] = useState(location?.state)
    const [statusCount, setStatusCount] = useState(null)

    // -------------API Calls song promotion & campaign

    useEffect(() => {
        if (campaignDetail.status) {
            switch (campaignDetail.status) {
                case 'UnderReview':
                    setStatusCount(25)
                    break;
                case 'SongPromotionLive':
                    setStatusCount(75)
                    break;
                case 'Completed':
                    setStatusCount(100)
                    break;
                case 'Rejected':
                    setStatusCount(0)
                    break;
                default:
                    break;
            }
        }
    }, [campaignDetail])

    // back to prev

    function backtoprevious() {
        history.push('/campaign')
    }

    return (
        <Box className='container-fluid py-2 px-4 creator-playlist-panel'>
            <Button className="text-black text-capitalize fs-5" onClick={backtoprevious} startIcon={<KeyboardBackspaceIcon />}>
                {campaignDetail?.songName || '--'}
            </Button>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                    <Card>
                        <Box position="relative" width="100%" height="160px" borderRadius={'8px'} overflow={'hidden'}>
                            <CardMedia
                                component="img"
                                style={{
                                    height: '200px',
                                }}
                                image={campaignDetail?.songPoster ? process.env.REACT_APP_BASEURL_MEDIA + '/media/' + campaignDetail?.songPoster : playlist}
                            />
                            <Chip className='chipStyle' label={campaignDetail?.source} />
                        </Box>
                        <CardContent>
                            <Typography variant="body2" component={'div'} className='d-flex align-items-baseline gap-2 flex-grow-1' sx={{ color: 'text.secondary', margin: '8px 0px' }}>
                                <Typography variant="subtitle2" gutterBottom>Song Name:</Typography> {campaignDetail?.songName || '--'}
                            </Typography>
                            {/* <Button className='text-body-secondary w-100 justify-content-end gap-1' onClick={() => history.push('/campaign/edit',{campaignDetail})}>
                                Edit <EditIcon fontSize={'small'} />
                            </Button> */}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                    <Card>
                        <Box className='text-end pt-2 px-3'>
                            <Chip label={campaignDetail?.status} />
                        </Box>
                        <CardContent >
                            <Box className="d-flex flex-wrap">

                                {[
                                    { label: 'Campaign Name:', value: campaignDetail?.campaignName || '--' },
                                    { label: 'Genre:', value: campaignDetail?.genreName || '--' },
                                    { label: 'Mood:', value: campaignDetail?.moodName || '--' },
                                    { label: 'Language:', value: campaignDetail?.languageName || '--' },
                                    { label: 'Start Date:', value: getDateAsPerFormat(campaignDetail?.startDate, 'dd/MM/yyyy') || '--' },
                                    { label: 'End Date:', value: getDateAsPerFormat(campaignDetail?.endDate, 'dd/MM/yyyy') || '--' },
                                    { label: 'Artist Accounts Tag:', value: campaignDetail?.artistAccountsTag || '--' },
                                    { label: 'Suggested Hastag:', value: campaignDetail?.suggestedHastag || '--' },
                                    { label: 'Special Notes:', value: campaignDetail?.notes || '--' },

                                ].map((item, index) => (
                                    <Typography
                                        key={index}
                                        variant="body2"
                                        component="div"
                                        className="d-flex align-items-baseline gap-2 "
                                        sx={{ color: 'text.secondary', margin: '8px 0px', flex: '0 0 48%' }}
                                    >
                                        <Typography variant="subtitle2" gutterBottom>
                                            {item.label}
                                        </Typography>
                                        {item.value}
                                    </Typography>
                                ))}
                                <GetMoodGenreLanguage label="Platform" items={campaignDetail?.platform || []} />
                            </Box>

                            <Box className="d-flex gap-2 mt-3">
                                <List sx={{ columnCount: 3 }}>
                                    {[
                                        { label: 'Submit campaign:', value: 'Done' },
                                        { label: 'Under review:', value: '25%' },
                                        { label: 'Live:', value: '75%' },
                                        { label: 'Completed:', value: '100%' },
                                        { label: 'Rejected:', value: '0%' },
                                    ].map((item, index) => (
                                        <ListItem
                                            key={index}
                                            disablePadding>
                                            <ListItemText primary={<Typography variant="body2" sx={{ color: 'text.secondary', fontWeight: '600' }}>{item.label}</Typography>} secondary={item.value} />
                                        </ListItem>
                                    ))}
                                </List>
                                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                    <CircularProgress variant="determinate" value={statusCount} size={115} />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography
                                            variant="caption"
                                            component="div"
                                            sx={{ color: 'text.secondary', fontSize: '20px' }}
                                        >
                                            {statusCount + `%`}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>

                    </Card>
                </Grid>
            </Grid>
        </Box >
    );
}
