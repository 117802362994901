import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, Stack, Typography, Tooltip, IconButton, Tab, Link } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ApiService from '../../../../_services/ApiService';
import reviewRating from '../../../../img/reviewRating.png'
import RatingReviewDialog from './RatingReviewDialog';
import { useSelector } from 'react-redux';
import SkeletonCard from '../../../../_helpers/reusablefunctions/SkeletonCard';
import NoResults from '../../../../components/reusableComponents/NoResults';
import { getStrPlatformImg } from '../../../../_helpers/reusablefunctions/getStreamingPlatform';

export default function MusicPlatforms() {
  const userData = useSelector((state) => state.userData.userData);
  const location = useLocation();
  const history = useHistory();
  const getMusicDetail = location?.state?.musicData
  const [relatedPlatfom, setRelatedPlatform] = useState([]);
  const [loader, setLoader] = useState(true)
  const [playlistId, setPlaylistId] = useState('')
  const [openRatingDialog, setOpenRatingDialog] = useState(false)

  const handleRouteBack = () => {
    history.push('/monetize-music')
  }

  function getSingleSong() {
    setLoader(true)
    let dataObj = {}
    dataObj.search = getMusicDetail?.id;
    dataObj.user_id = userData.id;
    ApiService.get('getrelatedStreamingPlatforms', dataObj).then(res => {
      setLoader(false)
      if (res.status === 200 || res.status === 201) {
        let arrayData = res.data.data;
        const data = arrayData.sort(function (a, b) {
          return parseInt(a.platform_order) - parseInt(b.platform_order);
        });
        setRelatedPlatform(data);
      }
    }).catch(error => {
      console.log(error)
    })
  }
  useEffect(() => {
    if (userData?.id) {
      getSingleSong()
    }
  }, [userData])

  function openReviews(data) {
    history.push("/reviews", { dialogValue: "Song", reviewViewDetail: data, preUrl: '/monetize-music' });
  }

  function openRatingHandler(id) {
    setOpenRatingDialog(true)
    setPlaylistId(id)
  }

  return (
    <Box sx={{ padding: '1rem 1rem 3rem 1rem' }}>
      <Box alignItems={'center'} display={'flex'} justifyContent={'space-between'} >
        <Button className='gap-3 text-black' onClick={handleRouteBack}><ArrowBackIcon /> {getMusicDetail?.name || getMusicDetail?.songTitle}</Button>
      </Box>
      <Box mt={2} mb={4} px={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={3} >
            <Card className="" role="box" tabIndex="0">
              <CardMedia
                component="img"
                style={{
                  height: '200px',
                }}
                image={getMusicDetail?.user_avatar
                  ? process.env.REACT_APP_BASEURL_MEDIA + 'media/' + getMusicDetail?.user_avatar
                  : getMusicDetail?.songPoster_url ? getMusicDetail?.songPoster_url 
                  : process.env.REACT_APP_BASEURL_MEDIA + '/media/' + getMusicDetail?.songPoster}
              />
              <CardContent >
                <Typography variant="body2"><strong>Name:</strong> {getMusicDetail?.name || getMusicDetail?.songTitle}</Typography>
                <Box className='d-flex align-items-center gap-2 my-1' >
                  <Typography variant="body2"><strong>Genre:</strong></Typography>
                  <Chip label={getMusicDetail?.genre?.genre} sx={{ backgroundColor: '#E6EEEF' }} />
                </Box>
                <Box className='d-flex align-items-center gap-2 my-1' >
                  <Typography variant="body2"><strong>Mood:</strong></Typography>
                  <Chip label={getMusicDetail?.mood?.mood} sx={{ backgroundColor: '#E6EEEF' }} />
                </Box>
                <Box className='d-flex align-items-center gap-2 my-1' >
                  <Typography variant="body2"><strong>Language:</strong></Typography>
                  <Chip label={getMusicDetail?.language?.language} sx={{ backgroundColor: '#E6EEEF' }} />
                </Box>
              </CardContent >
              <CardActions>
                <Button disabled={getMusicDetail.userId == userData?.id} size="small" color="primary" className='gap-2' onClick={() => getMusicDetail.userId == userData?.id ? null : openRatingHandler(getMusicDetail.id)}>
                  <img src={reviewRating} alt='review&rating' />
                  Rating & Review
                </Button>
                <Button size="small" color="primary" className='gap-2' onClick={() => openReviews(getMusicDetail)}>
                  View Reviews
                </Button>
              </CardActions>
            </Card >
          </Grid>
          <Grid item xs={12} md={8} lg={9} >
            {loader ?
              Array.from(new Array(8)).map((item, index) => (<Grid key={index} item xs={12} md={12} lg={12}>
                <SkeletonCard />
              </Grid>))
              :
              loader === false && relatedPlatfom.length === 0 ? (
                <NoResults />
              )
                : relatedPlatfom.map((data, index) => (
                  data?.status &&
                  <Box>
                    <Link key={index} href={data?.streamingSonglink || '#'} target="_blank">
                      <Card className='m-2'>
                        <CardContent className='p-2'>
                          <Box display={'flex'} alignItems={'center'} gap={2} >
                            <CardMedia
                              component="img"
                              sx={{ width: '50px', height: '50px', borderRadius: '100%' }}
                              image={getStrPlatformImg(data?.streamingPlatformKey)}
                            />
                            <Box width={'90%'} >
                              <Typography>{data?.streamingPlatformName}</Typography>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Link>
                  </Box>
                ))
            }

          </Grid>
        </Grid>
      </Box>
      <RatingReviewDialog dialogValue="Song" songId={playlistId} openRatingDialog={openRatingDialog} setOpenRatingDialog={setOpenRatingDialog} />
    </Box >
  )
} 
