import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box, Typography, IconButton, Drawer, Card, CardContent,
    Grid, Radio, List, ListItem, ListItemButton, ListItemText,
    ListItemIcon, Avatar, Slider, TextField,
    Button,
    ListItemAvatar
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';
import { fetchStreamingPlatformList } from "../../redux";
import { handleSongList } from '../../_services/creater/creatorSongList.service';
import imgThumbnail from '../../img/thumbnail.png'
import ArtistCampaign from "./artistCampaign";
import BudgetPlan from "./budgetPlan";
import { useHistory, useLocation } from "react-router-dom";
import ApiService from "../../_services/ApiService";
import CampaignHeader from './campaignHeader'
import useHandleRazorPayment from "./paymentRazorpay";
import { getStrPlatformImg } from "../../_helpers/reusablefunctions/getStreamingPlatform";
export default function PromotionCampaign() {
    const dispatch = useDispatch();
    const { successSnack, errorSnack } = useSnackDispatcher();
    const history = useHistory();
    const location = useLocation().state;
    // State management
    const [activeStep, setActiveStep] = useState(0);
    const [promotionData, setPromotionData] = useState([]);
    const [campaignGetData, setCampaignGetData] = useState([]);
    const [songList, setSongList] = useState([]);
    const [campaignType, setCampaignType] = useState(location?.campaignDetail?.id ? 'reelcampaign' : null);
    const [artistCount, setArtistCount] = useState(0);
    const [artistAmount, setArtistAmount] = useState("");
    const [selectedPlatform, setSelectedPlatform] = useState(location?.campaignDetail?.platform?.platformId || "");
    const [nextDisable, setNextDisable] = useState(true);
    const [amounterror, setAmounterror] = useState(false);
    const [stremingPlatform, setStremingPlatform] = useState([]);
    const [songId, setSongId] = useState(location?.campaignDetail?.song || '');
    const [returnValues, setReturnValues] = useState(null);
    const [initialCampaignValues, setInitialCampaignValues] = useState(null);
    const [activePlan, setActivePlan] = useState(null)
    const [packageAmount, setPackageAmount] = useState(null);
    const [isBeforeUnloadActive, setIsBeforeUnloadActive] = useState(false);
    // -------------------- promotioncampaign states
    const [formValues, setFormValues] = useState({
        selectedGenres: '',
        selectedMood: '',
        selectedLanguage: '',
        campaignName: '',
        taggetAccount: '',
        suggHashtag: '',
        specialNote: '',
        selectedCampaignPlatform: [],
        startDate: '',
        endDate: ''
    });
    const [formDateValues, setFormDateValues] = useState({
        startDate: '',
        endDate: ''
    });

    // Redux selectors
    const streamingPlatform = useSelector((state) => state.streamingPlatform.streamingPlatform);
    const userData = useSelector((state) => state.userData.userData);
    const [activeBtnText, setActiveBtnText] = useState('Next')
    const handlePayment = useHandleRazorPayment({ packageAmount, songId });
    // Review promotion details
    const reviewList = useMemo(() => [
        { id: 1, img: songId?.songPoster_url ? songId?.songPoster_url : imgThumbnail, name: songId?.songTitle, label: songId?.artists },
        { id: 2, img: '', name: 'Goal', label: 'Total no. of artist review' },
        { id: 3, img: '', name: 'Audience', label: 'Artist whose genre are similar to the song' },
        { id: 4, img: '', name: 'Amount', label: artistAmount + ' Rs/-' },
    ], [artistAmount, songId])

    // Campaign details
    const campaignData = [
        {
            type: "promotion",
            text: "Artist Community Campaign",
            subText: "Run campaigns on our app where artists publish content, others listen, and the platform provides ratings and reviews."
        },
        {
            type: "reelcampaign",
            text: "Creator Campaign",
            subText: "Artists run campaigns on GN Creator, where influencers create reels and earn money by promoting the content."
        }
    ];

    // ---------- on-load page confirmation 
    const handleBeforeUnload = (event) => {
        const confirmationMessage = "Are you sure you want to leave? Changes you made may not be saved.";
        event.preventDefault();
        event.returnValue = confirmationMessage;
        return confirmationMessage;
    };

    const toggleBeforeUnload = () => {
        if (isBeforeUnloadActive) {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        } else {
            window.addEventListener("beforeunload", handleBeforeUnload);
        }
        setIsBeforeUnloadActive(!isBeforeUnloadActive);
    };

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);
    // Effect to fetch streaming platforms
    useEffect(() => {
        dispatch(fetchStreamingPlatformList());
    }, [dispatch]);

    // Effect to fetch user songs
    useEffect(() => {
        if (userData?.id) {
            fetchUserSongs();
        }
    }, [userData]);

    useEffect(() => {
        if (streamingPlatform.length) {
            setStremingPlatform(streamingPlatform)
        }
    }, [streamingPlatform])

    // Effect to validate the "Next" Button
    useEffect(() => {
        if (artistCount > 0 && artistAmount) {
            setNextDisable(false);
        }
    }, [artistCount, artistAmount]);

    // Fetch user's song list
    const fetchUserSongs = () => {
        const dataObj = { userId: userData.id };
        handleSongList(dataObj, "GETBYUSER")
            .then((response) => {
                if (response.status === 200) {
                    setSongList(response.data.results);
                }
            })
            .catch(() => {
                errorSnack("Failed to fetch songs");
            });
    };

    // Handlers for navigation
    const handleNext = () => {
        let shouldDisableNext = true;

        if (campaignType === 'promotion') {
            if (activeStep === 3) {
                shouldDisableNext = false;
                setActiveBtnText('Finish');
            }
            if (activeStep === 4) {
                artistcampaignpromotion();
                return;
            }
        } else if (campaignType === 'reelcampaign') {
            if (activeStep === 2) {
                shouldDisableNext = false;
                setActiveBtnText('Payment');
            }
            if (activeStep === 3) {
                // successSnack("Reel Campaign created successfully!");
                artistcampaignpromotion();
                handlePayment()
                return;
            }
        }
        setNextDisable(shouldDisableNext);

        setActiveStep((prev) => prev + 1);
        // Call artistcampaignpromotion only if it's a promotion campaign and we're not at the final step
        // if ((campaignType === 'promotion' && activeStep == 3)) {
        //     artistcampaignpromotion();
        // }
    };


    const handlePrevious = () => {
        setActiveStep((prev) => {
            setActiveBtnText('Next');
            setNextDisable(false)
            return prev - 1
        });
    };

    const handleNextStates = (getValues) => {
        setReturnValues(getValues)
        return getValues;
    }

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setNextDisable(false)
        setFormValues(prev => ({ ...prev, [name]: value }));
    };

    const releaseDateChangeHandler = (newValue, pickerType) => {
        setNextDisable(false)
        const formattedDate = newValue.format('YYYY-MM-DD');
        setFormDateValues(prev => ({ ...prev, [pickerType]: formattedDate }));
    };

    //  API Calling 
    function artistcampaignpromotion() {
        let postData = new FormData()
        if (songId.id) {
            if (campaignType == "promotion" && activeStep != 0) {
                let digits = artistAmount / artistCount;
                postData.append('song', songId.id)
                postData.append('platforms', selectedPlatform)
                postData.append('promotion', 'True')
                if ((artistCount > 0 && artistAmount) || activeStep == 2) {
                    postData.append('number_of_artists', artistCount)
                    postData.append('budget_amount', artistAmount)
                    postData.append('per_person_budget', digits.toFixed(4))
                }
                if (artistAmount !== promotionData?.budget_amount || artistCount !== promotionData?.number_of_artists || selectedPlatform !== promotionData?.platforms) {
                    ApiService.post('songPromotion', postData).then((res) => {
                        if (res.status == 200 || res.status == 201) {
                            setPromotionData(res?.data?.data[0]?.fields)
                            successSnack("Artist campaign created successfully!");
                            history.push('/campaign')
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }
            } else {
                const fields = {
                    song: songId?.id || '',
                    campaignName: formValues?.campaignName || '',
                    genre: formValues?.selectedGenres || '',
                    mood: formValues?.selectedMood || '',
                    language: formValues?.selectedLanguage || '',
                    platform: formValues?.selectedCampaignPlatform || '',
                    startDate: formDateValues?.startDate || '',
                    endDate: formDateValues?.endDate || '',
                    artistAccountsTag: formValues?.taggetAccount || '',
                    suggestedHastag: formValues?.suggHashtag || '',
                    notes: formValues?.specialNote || '',
                    packages: activePlan
                };
                if (initialCampaignValues && JSON.stringify(fields) === JSON.stringify(initialCampaignValues)) {
                    console.log("No changes detected, skipping API call.");
                    return;
                }
                Object.entries(fields).forEach(([key, value]) => {
                    if (value) {
                        postData.append(key, value);
                    }
                });
                ApiService.post('reelCampaign', postData).then((res) => {
                    if (res.status == 200 || res.status == 201) {
                        setCampaignGetData(res?.data?.data[0]?.fields)
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        }
    }

    // Change handlers
    const handleCampaignChange = (e, data) => {
        e.preventDefault();
        setCampaignType(data.type);
        setNextDisable(false);
    };

    const handleSongSelection = (e, data) => {
        e.preventDefault();
        setSongId(data);
        setNextDisable(false);
    };

    const handleArtistCountChange = (event, newValue) => {
        setArtistCount(newValue);
    };

    const handleArtistAmountChange = (e) => {
        const { value } = e.target;
        setArtistAmount(value);

        if (e.type === "blur" && value > 100000) {
            setNextDisable(true);
            setAmounterror(true);
        } else {
            setAmounterror(false);
        }
    };

    const onchangePlatformHandler = (e, data) => {
        e.preventDefault();
        setNextDisable(false)
        setSelectedPlatform(data.platformId)
    }

    // Step renderers
    const renderStepContent = () => {

        switch (activeStep) {
            case 0:
                return (
                    <Box display="flex" gap={3}>
                        {campaignData.map((data) => (
                            <Card key={data.type}>
                                <CardContent>
                                    <ListItemButton onClick={(e) => handleCampaignChange(e, data)}>
                                        <ListItemText primary={data.text} secondary={data.subText} />
                                        <Radio checked={campaignType === data.type} />
                                    </ListItemButton>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                );

            case 1:
                return (
                    <Grid container spacing={2}>
                        {songList.length ? songList.map((song) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={song.id}>
                                <Card>
                                    <CardContent className="p-2">
                                        <ListItemButton className="gap-2" onClick={(e) => handleSongSelection(e, song)}>
                                            <Avatar sx={{ height: '50px', width: '50px' }}
                                                src={song?.songPoster_url ? song?.songPoster_url : imgThumbnail}
                                                alt={song.songTitle} />
                                            <ListItemText sx={{ textTransform: 'capitalize' }} primary={song.songTitle} />
                                            <Radio checked={songId?.id === song.id} />
                                        </ListItemButton>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )) : <Typography variant="body2" className="text-center w-100 fs-6">Can't find your tracks</Typography>}
                    </Grid>
                );

            case 2:
                return (
                    ((activeStep >= 2 && activeStep <= 4) && campaignType == 'promotion') ?
                        <Card sx={{ height: '50vh', overflow: 'auto' }}>
                            <CardContent>
                                <List>
                                    {stremingPlatform.length && stremingPlatform.map(d => (
                                        <ListItem key={d.platformId} disablePadding >
                                            <ListItemButton>
                                                <ListItemAvatar sx={{minWidth:'40px'}}>
                                                    <Avatar sx={{ height: '30px', width: '30px' }}
                                                        alt={d?.platformName}
                                                        src={getStrPlatformImg(d?.platform_key)} />
                                                </ListItemAvatar>
                                                <ListItemText primary={d?.platformName} />
                                                <ListItemIcon>
                                                    <Radio
                                                        checked={selectedPlatform === d.platformId}
                                                        onChange={(e) => onchangePlatformHandler(e, d)}
                                                        value={d.platformId}
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': d.platformId }}
                                                    />
                                                </ListItemIcon>
                                            </ListItemButton>
                                        </ListItem>))}
                                </List>
                            </CardContent>
                        </Card>
                        :
                        <ArtistCampaign songId={songId} handleFormChange={handleFormChange} releaseDateChangeHandler={releaseDateChangeHandler}
                            formValues={formValues} stremingPlatform={stremingPlatform} formDateValues={formDateValues} />
                )

            case 3:
                return (
                    campaignType == 'promotion' ?
                        <Card marginTop={3}>
                            <CardContent>
                                <Box sx={{ width: "80%", height: '70%', marginTop: '30px' }}>
                                    <Typography>Number of Artists</Typography>
                                    <Slider
                                        value={artistCount}
                                        onChange={handleArtistCountChange}
                                        min={0}
                                        max={100}
                                        valueLabelDisplay="auto"
                                        sx={{ color: '#ED1C24' }}
                                    />
                                    <Box marginTop={2}>
                                        <TextField
                                            sx={{ width: '100%' }}
                                            placeholder="Amount"
                                            value={artistAmount}
                                            onChange={handleArtistAmountChange}
                                            onBlur={handleArtistAmountChange}
                                            error={amounterror}
                                            helperText={amounterror ? "Amount cannot exceed 100,000" : ""}
                                        />
                                    </Box>
                                    <Box marginTop={4}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={6}>
                                                <Typography variant="body1" fontWeight="bold">
                                                    Per person budget
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    Estimated daily review
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} textAlign="right">
                                                <Typography variant="body1" fontWeight="bold">
                                                    {artistAmount && artistCount ? (artistAmount / artistCount).toFixed(2) : '0.00'} Rs/-
                                                    {/* {artistAmount && artistCount ? artistAmount / artistCount : 0} Rs/- */}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    10 reviews/day
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                        :
                        <Card marginTop={3}>
                            <CardContent>
                                <BudgetPlan setPackageAmount={setPackageAmount} setActivePlan={setActivePlan} activePlan={activePlan} handleNextStates={handleNextStates} />
                            </CardContent>
                        </Card>

                );

            case 4:
                return (
                    <Card marginTop={3}>
                        <CardContent>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItemAvatar sx={{ width: '80px', height: '80px', marginRight: '10px' }}>
                                            <img alt={songId?.songTitle || '-'} src={songId?.songPoster_url || imgThumbnail} className='w-100 h-100 rounded-1' />
                                        </ListItemAvatar>
                                        {reviewList.map((data, index) => (
                                            <ListItem key={index} alignItems="flex-start" sx={{ paddingTop: '0px', alignItems: 'center' }}>
                                                <ListItemText
                                                    primary={data?.name || '-'}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                sx={{ color: 'text.primary', display: 'inline' }}
                                                            >
                                                            </Typography>
                                                            {data?.label || '-'}
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>))}
                                    </List>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )

            default:
                return <Typography>Invalid Step</Typography>;
        }
    };

    return (
        <div>
            <Box display="flex" alignItems={'center'} sx={{ cursor: 'pointer' }} p={2} onClick={() => history.push('/campaign')}>
                <IconButton aria-label="back" className='fs-5 gap-2 bg-transparent' onClick={toggleBeforeUnload}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="body1">
                    Back To Campaign
                </Typography>
            </Box>

            <Box px={5} mb={5} height={'100%'}>
                <CampaignHeader campaignType={campaignType} activeStep={activeStep} />
                {renderStepContent()}
                <Box mt={2} display="flex" justifyContent="space-between">
                    <Button onClick={handlePrevious} disabled={activeStep === 0}>Previous</Button>
                    <Button className='gn-actionbtn' onClick={handleNext} disabled={location?.campaignDetail?.id ? false : nextDisable}>
                        {activeBtnText}
                    </Button>
                </Box>
            </Box>
        </div>
    );
}


