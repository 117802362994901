

import React, { useState } from "react";
import { auth, storage } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const FirebaseImageStore = ({ selectedFile, functionCall }) => {
    const userData = useSelector((state) => state.userData.userData);

    const saveAvatar = async () => {
        const filePath = `images/${userData.creator_auth}/${Date.now()}_${selectedFile.name}`;
        // const filePath = `images/${userData?.creator_auth}/${selectedFile.name}`;
        const storageRef = ref(storage, filePath);
        const uploadTask = uploadBytesResumable(storageRef, selectedFile);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(`Upload is ${progress}% done`);
            },
            (error) => {
                console.error("Upload failed:", error);
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                if (selectedFile) {
                    functionCall({ downloadURL: downloadURL, filePath })
                }
            }
        );
    }

    return { saveAvatar };

}
export default FirebaseImageStore;