import {
    FETCH_USER_TYPE_FAILURE,
    FETCH_USER_TYPE_REQUEST,
    FETCH_USER_TYPE_SUCCESS
} from "./userTypeTypes";
const initialState = {
    loading: false,
    userTypeData: "",
    // selectedQuestionList: null,
    error: '',
  }
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case   FETCH_USER_TYPE_REQUEST:
        return {
          ...state,
          loading: true
        }
      case FETCH_USER_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          userTypeData: action.payload,
          error: ''
        }
      case FETCH_USER_TYPE_FAILURE:
        return {
          ...state,
          loading: false,
          userTypeData: [],
          error: action.payload
        }
       
      default: return state
    }
  }
  
  export default reducer
  