// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getMessaging } from 'firebase/messaging';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDS-vprOPZEekrS34ybegxfjAMEpv65Slc",
  authDomain: "gn-app-prod.firebaseapp.com",
  databaseURL: "https://gn-app-prod-default-rtdb.firebaseio.com",
  projectId: "gn-app-prod",
  storageBucket: "gn-app-prod.firebasestorage.app",
  messagingSenderId: "763342331487",
  appId: "1:763342331487:web:c0c34d2a54439421cd05ea",
  measurementId: "G-KEWLGS4DB2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const messaging = getMessaging(app)
const storage = getStorage(app);

export { auth, RecaptchaVerifier, signInWithPhoneNumber, db, messaging, storage }
