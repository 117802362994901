// import * as React from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { useEffect, useState, useMemo } from 'react';
// import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';
// import { Box, Button, Chip, Stack, styled, Typography } from '@mui/material';
// import noCampaign from '../../img/Collabrate/Group.png'
// import PromotionCampaign from './promotionCampaign';

// const StyledChip = styled(Chip)(({ theme }) => ({
//     borderRadius: '10px',
//     color: '#545454',
//     background: '#fff',
//     border: '0px',
//     padding: '6px 20px 6px 20px',
//     fontSize: '14px',
//     '&:hover': {
//         backgroundColor: '#ED1C24',
//         color: '#fff',
//     },
//     '&.MuiChip-clickable:focus, &.MuiChip-clickable:hover': {
//         backgroundColor: '#ED1C24',
//         color: '#fff',
//     },
//     '&.MuiChip-clickable': {
//         '&.Mui-active': {
//             backgroundColor: '#ED1C24',
//             color: '#fff',
//         },
//     },
// }));
// const campaignList = [
//     { label: 'promotion', value: ["Artist Community"] },
//     { label: 'creatorcampaign', value: ["Creator Campaign"] },
// ]
// export default function Campaign() {
//     const { successSnack, errorSnack, warningSnack, infoSnack } = useSnackDispatcher();
//     const dispatch = useDispatch()
//     const [activeChip, setActiveChip] = useState('0-0');
//     const [activeLabel, setActiveLabel] = useState('promotion');
//     const [openCampaignModal, setOpenCampaignModal] = useState(false);


//     const handleChipClick = (i, label, value) => {
//         setActiveChip(i)
//         setActiveLabel(label)
//     }

//     return (
//         <Box className='container-fluid py-4 px-4 creator-playlist-panel'>
//             <Box className='d-flex justify-content-between'>
//                 <h2>Campaign</h2>
//                 <Button variant='contained' className='gn-actionbtn' onClick={() => { setOpenCampaignModal(true) }}>
//                     Create Your First Campaign Now!
//                 </Button>
//             </Box>
//             <Box className="d-flex align-items-center justify-content-between my-4 flex-wrap gap-2">
//                 <Stack direction="row" spacing={1}>
//                     {campaignList.map((d, i) =>
//                         d.value.map((v, index) => (
//                             <StyledChip
//                                 key={`${i}-${index}`}
//                                 label={v}
//                                 component="a"
//                                 variant="outlined"
//                                 clickable
//                                 onClick={() => handleChipClick(`${i}-${index}`, d.label, v)}
//                                 className={activeChip === `${i}-${index}` ? 'Mui-active' : ''}
//                             />
//                         ))
//                     )}
//                 </Stack>
//             </Box>
//             <Box className='d-flex align-items-center flex-column gap-2'>
//                 <img src={noCampaign} alt='no campaign' />
//                 <Typography variant='h5' >No Campaigns Yet!</Typography>
//                 <Typography variant='body1' >It looks like you haven’t started a campaign. Don’t miss out on promoting your content to the right audience!</Typography>
//             </Box>

//             <PromotionCampaign
//                 songData={[]}
//                 setOpenCampaignModal={setOpenCampaignModal}
//                 openCampaignModal={openCampaignModal}
//             />
//         </Box >
//     );
// }
