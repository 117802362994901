import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Grid, Chip, Avatar, Button, IconButton, Rating, Divider, Dialog, DialogContent, CircularProgress } from '@mui/material';
import SwipImageCarousel from './SwipImageCarousel';
import { getSocialIcon } from '../../_helpers/reusablefunctions/socialIcons';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import Recommendation from './Recommendation';
import profileImge from '../../img/profileImage.png'
import ApiService from '../../_services/ApiService';
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';
import SingleChat from './SingleChat';
import ChatIcon from '@mui/icons-material/Chat';
import RecommendIcon from '@mui/icons-material/Recommend';

export default function SwipUserContent() {
    const { errorSnack, infoSnack, successSnack } = useSnackDispatcher();
    const userData = useSelector((state) => state.userData.userData);
    const location = useLocation()
    let history = useHistory();
    const getNearbyUser = location?.state?.data?.results || location?.state?.data
    const statusRequest = location?.state
    const [count, setCount] = useState(0)
    const [openRecommendDialog, setOpenRecommendDialog] = useState(false)
    const [recommendData, setRecommendData] = useState()
    const [openChat, setOpenChat] = useState(false)
    const chatRef = useRef(null);
    const [loader, setLoader] = useState(false);

    const nextHandler = () => {

        if (count + 1 == getNearbyUser.length) {
            setCount(0)
        } else {
            setCount(count + 1)
        }
    }

    function connectRejectHandle(e) {
        e.preventDefault();

        let payload = {
            sender_id: userData?.id,
            receiver_id: getNearbyUser[count]?.id
        }
        setLoader(true)
        ApiService.post('postCollabArtist', payload).then((res) => {
            if (res.status == 201 || res.status == 200) {
                successSnack('Connection request sent...')
                nextHandler()
            }
            setLoader(false)
        })
            .catch(error => {
                setLoader(false)
                console.log(error)
            })
    }


    // ------------- chat closing 
    const handleClickOutside = (event) => {
        if (chatRef.current && !chatRef.current.contains(event.target)) {
            setOpenChat(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    // --------------- commoncategorymoodgenre -----------

    const GenreMoodCategory = ({ title, data, labelKey }) => {
        return (
            <Box>
                <Typography variant="h6" mt={2} gutterBottom>
                    {title}
                </Typography>
                <Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                        {data?.map((item) => (
                            <Chip key={item.id} label={item[labelKey]} sx={{ backgroundColor: '#E6EEEF' }} />
                        ))}
                    </Box>
                </Box>
            </Box>
        );
    };


    const SocialLinks = ({ data }) => {
        return (
            <>
                <Typography variant="h6" mt={2} gutterBottom>
                    Socials
                </Typography>
                <Box>
                    <Box className='flex-wrap' sx={{ display: 'flex', gap: 2, marginTop: 1 }}>
                        {data?.map((d, i) => (
                            <IconButton key={i} sx={{ backgroundColor: '#FFF1F1', fontSize: '12px', width: d?.url?.length > 50 ? '75%' : 'auto' }}
                                className='rounded-2 gap-2 wordBreak justify-content-start' href={d.url} target="_blank">
                                {getSocialIcon(d?.socialType?.socialTypeCode)}
                                {d?.url}
                            </IconButton>
                        ))}

                    </Box>
                </Box>
            </>
        )
    }

    // ------------------experience comman const for sender and collab

    const ExperienceLinks = ({ data }) => {
        return (
            <>
                <Typography variant="h6" mt={2} gutterBottom>
                    Experience
                </Typography>
                <Box>
                    {/* <Typography variant="body2"><strong>Artist Experience:</strong></Typography> */}
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                        {data?.map((experience, index) => (
                            <Box key={index}>
                                <Typography variant='body2' className='fw-bolder d-flex align-items-baseline gap-2'>
                                    Company Name:
                                    <Typography variant="subtitle1" color="textSecondary">{experience?.companyName}</Typography>
                                </Typography>
                                <Typography variant='body2' className='fw-bolder d-flex align-items-baseline gap-2'>
                                    Title:
                                    <Typography variant="subtitle1" color="textSecondary">{experience?.title}</Typography>
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </>
        )
    }


    // ------------------recommendation comman const for sender and collab

    const RecommendationLink = ({ data }) => {
        return (
            <>
                <Typography variant="h6" mt={2} gutterBottom>
                    Recommendations
                </Typography>
                <Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', gap: 1, marginTop: 1, marginRight: 2, marginBottom: 3 }}>
                        {data?.map((data, index) => (
                            <Box key={index}>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Box display="flex" alignItems="center">
                                        <Avatar
                                            src={data?.recommended_by_avatar ? process.env.REACT_APP_BASEURL_MEDIA + '/media/' + data?.recommended_by_avatar :
                                                data?.recommended_by_avatar_url ? data?.recommended_by_avatar_url : profileImge}
                                        alt="Sunil Bhadoriya"
                                        sx={{ marginRight: 2 }}
                                        />
                                        <Box>
                                            <Typography variant="body1" fontWeight="bold">
                                                {data?.recommended_by_name || '-'}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {data?.recommended_by_role || '-'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <Rating value={data?.rating} precision={0.1} readOnly size="small" />
                                        <Typography variant="body2" color="textSecondary" ml={0.5}>
                                            {`${data?.rating}/5`}
                                        </Typography>
                                    </Box>
                                </Box>
                                {/* Feedback Content */}
                                <Typography variant="body2" color="textSecondary" mt={1}>
                                    {data?.feedback || '-'}
                                </Typography>
                            </Box>
                        )) || '-'}
                    </Box>
                </Box>
            </>
        )
    }


    const userDetails = (data, index) => {
        return (
            <Grid key={index} item xs={12} sm={12} md={8} lg={8} height={'100%'} >
                {statusRequest?.status == "" && statusRequest?.page == 'artist' ? <Box textAlign={'end'}>
                    <Button className='gn-actionbtn-color' onClick={() => { setRecommendData(data); setOpenChat(!openChat) }}><ChatIcon /> Chat</Button>
                    <Button variant="text" className='gn-actionbtn-color' onClick={() => { setRecommendData(data); setOpenRecommendDialog(true) }}><RecommendIcon /> RECOMMEND NOW</Button>
                </Box> : null}

                <Box sx={{ overflowY: 'auto', height: '95%' }}>
                    <Typography variant="h6" gutterBottom>
                        General Information
                    </Typography>

                    <Box className='d-flex gap-3 flex-wrap' >
                        <Typography variant="body2"><strong>Name:</strong> {data?.sender?.name || data?.name || '--'}</Typography>
                        <Typography variant="body2"><strong>Email:</strong> {data?.sender?.email || data?.email || '--'}</Typography>
                        <Typography variant="body2"><strong>Language:</strong> {data?.sender?.languages?.map(d => d.language).join(',') || data?.languages?.map(d => d.language).join(',') || '--'}</Typography>
                        <Typography variant="body2"><strong>Location:</strong> {data?.sender?.city || data?.city || '--'}</Typography>
                        <Typography variant="body2" component={'div'} width={'100%'}><strong>About:</strong> {data?.sender?.about || data?.about || '--'}</Typography>
                    </Box>
                    {/* ---------------- comman code for category, mood and genre */}
                    <GenreMoodCategory
                        title="Artist Category"
                        data={data?.sender?.category || data?.category || []}
                        labelKey="category"
                    />
                    <GenreMoodCategory
                        title="Genre"
                        data={data?.sender?.genres || data?.genres || []}
                        labelKey="genre"
                    />
                    <GenreMoodCategory
                        title="Songs"
                        data={data?.sender?.songs || data?.songs || []}
                        labelKey="songTitle"
                    />
                    {/* ----------------------- social --------------- */}
                    <SocialLinks
                        data={data?.sender?.social || data?.social || []}
                    />
                    {/* -------- experience area */}
                    {data?.sender?.experience.length || data?.experience?.length ? <ExperienceLinks
                        data={data?.sender?.experience || data?.experience || []}
                    /> : null}

                    {/* -------- recommendation area */}


                    {data?.sender?.received_recommendations.length || data?.received_recommendations?.length ? <RecommendationLink
                        data={data?.sender?.received_recommendations || data?.received_recommendations || []}
                    /> : null}

                </Box>
            </Grid>
        )
    }

    return (
        <Box className={'container-fluid py-4 px-4 creator-playlist-panel'}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                <h2>{getNearbyUser?.status == "Pending" ? 'Artist Profile' : 'Collaboration'}</h2>
            </Box>
            <Box sx={{ padding: '20px', margin: '20px 0px 50px 0px', height: "550px", boxShadow: '0px 2px 16px 1px rgba(76, 78, 100, 0.12)', backgroundColor: '#fff', borderRadius: '10px' }}>
                <Grid container spacing={3} height={'95%'}>
                    {statusRequest.status == "" || statusRequest?.page == 'artist' ? null : <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2 }}>
                            <Button variant="text"
                                onClick={nextHandler}
                                className='gn-actionbtn-color'>Reject</Button>
                            <Button variant="outlined"
                                onClick={(e) => connectRejectHandle(e)}
                                className='gn-actionbtn-outline gn-actionbtn-color'>
                                {loader ? <CircularProgress color="inherit" size={20} /> : 'Connect'}
                            </Button>
                            <Button variant="contained"
                                className='gn-actionbtn'
                                onClick={nextHandler}
                                disabled={getNearbyUser.length == 1}
                            >Next</Button>
                        </Box>
                    </Grid>}
                    <Grid item xs={12} sm={12} md={6} lg={4} height={'100%'} className={statusRequest?.page == 'artist' ? '' : 'pt-2'}>
                        <SwipImageCarousel count={count} />
                    </Grid>


                    {getNearbyUser.length ? getNearbyUser.slice(count, count + 1).map((data, index) => (
                        userDetails(data, index)
                    )) : userDetails(getNearbyUser)}

                </Grid>
            </Box>
            <Recommendation openRecommendDialog={openRecommendDialog} setOpenRecommendDialog={setOpenRecommendDialog} recommendData={recommendData} />
            {
                openChat && (
                    <div ref={chatRef} className="single-chat-container">
                        <SingleChat chatData={recommendData} />
                    </div>
                )
            }
        </Box >
    )

}