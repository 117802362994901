import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Chip, Avatar, Button, IconButton, ListItem, List, ListItemText } from '@mui/material';
import { getUserLocation } from '../../_helpers/reusablefunctions/geoLocation';
import ApiService from '../../_services/ApiService';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { getCategory } from '../../_services/profile';
import { setLoader } from '../../redux';
export default function SwipUsercategory() {
    const userDetail = useSelector((state) => state.userData.userData);
    const dispatch = useDispatch()
    const [userData, setUserData] = useState([])
    const [category, setCategory] = useState([])
    const history = useHistory()
    const [location, setLocation] = useState({
        latitude: null,
        longitude: null,
        error: null,
    });

    useEffect(() => {
        dispatch(setLoader(true))
        ApiService.get('getSwipCategory').then((res) => {
            dispatch(setLoader(false))
            console.debug("swipusergenre", res)
            setCategory(res?.data)
        }).catch(function (error) {
            dispatch(setLoader(false))
            console.log(error);
            console.log(error.response);
            return error.response;
        })
        getUserLocation()
            .then((coords) => {
                setLocation({ latitude: coords.latitude, longitude: coords.longitude });
            })
            .catch((error) => {
                setLocation({ latitude: null, longitude: null, error: error });
            });
    }, [])

    const submitHandler = (data) => {
        if (location.latitude && location.longitude) {
            let payload = {
                latitude: location.latitude,
                longitude: location.longitude,
                category: data.category,
                user_id: userDetail?.id,
            }
            dispatch(setLoader(true))
            ApiService.get('getCollabUsers', payload).then(res => {
                if (res.status == 200) {
                    dispatch(setLoader(false))
                    setUserData(res?.data)
                    history.push('/collaboration', { data: res?.data, status: 'user', page: 'collab-list' })
                }
            }).catch(error => {
                dispatch(setLoader(false))
                console.log(error)
            })
        }
    }

    return (
        <Box className='container-fluid py-4 px-4 creator-playlist-panel'>
            <Box sx={{ padding: '20px', margin: '20px 0px 50px 0px', boxShadow: '0px 2px 16px 1px rgba(76, 78, 100, 0.12)', backgroundColor: '#fff', borderRadius: '10px' }}>
                <Box>
                    <Typography variant="h6" gutterBottom>
                        Are you looking for?
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                        Please select an option below to continue
                    </Typography>

                    <List className='d-flex gap-2 flex-wrap'>
                        {category.length ? category.map((option, index) => (
                            <ListItem sx={{ maxWidth: '180px' }} key={index} disablePadding>
                                <Button
                                    onClick={() => submitHandler(option)}
                                    variant="outlined"
                                    fullWidth
                                    endIcon={<PersonIcon />}
                                    sx={{
                                        my: 1,
                                        borderRadius: 2,
                                        textTransform: 'none',
                                        justifyContent: 'flex-start',
                                        border: '1px solid #0000001A',
                                        boxShadow: '0px 4px 8px -4px #4C4E646B',
                                        color: '#000000CC'

                                    }}
                                >
                                    <ListItemText primary={option.category} />
                                </Button>
                            </ListItem>
                        )) : null}
                    </List>
                </Box>
            </Box>
        </Box>
    )

}