import { combineReducers } from 'redux'
import loader from './loader/loaderReducer'
import userData from './user/userDataReducer'
import userDetail from './loginUser/loginUserReducer'
import snackBar from './snackBar/snackBarReducer'
import releaseSongList from './songRelease/songReleaseReducer'
import streamingPlatform from './streamingPlatform/streamingPlatformReducer'
import studioAssociatesStore from './studioAssociates/studioAssociatesDataReducer'
import { USER_LOGOUT } from './logout/logoutTypes'
import releaseAlbumReducer from './releaseAlbum/releaseAlbumDataReducer'
import royaltyDataReducer from "./royalty/royaltyDataReducer"
import userTypeReducer from "./userType/userTypeReducer"

const appReducer  = combineReducers({
    loader:loader,
    userData:userData,
    userDetail:userDetail,
    snackBar:snackBar,
    releaseSongList:releaseSongList,
    streamingPlatform:streamingPlatform,
    studioAssociatesStore:studioAssociatesStore,
    releaseAlbumReducer:releaseAlbumReducer,
    royaltyDataReducer:royaltyDataReducer,
    userTypeReducer:userTypeReducer,
})

const rootReducer = (state, action) => {
    if (action.type === USER_LOGOUT) {
      // Reset the state to its initial values on user logout
      state = undefined;
    }
    return appReducer(state, action);
  };

export default rootReducer
