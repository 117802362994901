import logo from './logo.svg';
import React, { useEffect, useState } from 'react';
import './App.css';
import Login from './admin/login';
import SignUp from './admin/signUp';
import Dashboard from './module/dashboard/dashboard';
import Register from './admin/register';
import InvoiceList from '../src/module/invoice/InvoiceList'
import Invoice from '../src/module/invoice/Invoice'
import { Backdrop, } from '@mui/material';
import { CircularProgress } from '@mui/material';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Redirect,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import Profile from './module/profile/profile';
import Services from './module/services/services';
import ServicesPlan from './module/services/servicesPlan';
import Artists from './module/services/artists';
import ShoppingCart from './module/services/shoppingCart';
import Helpdesk from './module/helpdesk/helpdesk';
import TrackTicket from './module/helpdesk/trackTicket';
import Issue from './module/helpdesk/issue';
import PrivateRoute from './PrivateRoute';
import Order from "../src/module/order/Order";
import Subscription from './module/subscription/subscription';
import Placement from './module/placement/placement';
import { useSelector, useDispatch } from 'react-redux'
// import { setLoader } from './redux';
import Creator from '../src/module/creator';
import { Card } from '../src/module/card'
import Creater from './module/creater/creater';
import RegisterUsers from './admin/RegisterUsers';
import { removeUserToken } from './_helpers/auth/secureToken'
import Forgot from './admin/forgot';
import ResetPassword from './admin/resetPassword';
import ChangePassword from './admin/changePassword';
import PageNotFound from './admin/PageNotFound';
import SongListComp from './module/creater/songHub/songList/SongListComp';
import ArtistVerification from './module/profile/verifyArtist/ArtistVerification';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SingupVerification from './admin/SingupVerification';
import Enquiries from './module/creater/enquiry/Enquiries';
import AddSubmitSong from './module/submitSong/AddSongForRelease';
import SongList from './module/submitSong/SongList';
import DemoIndex from './module/submitDemo/DemoIndex';
import AddDemos from './module/submitDemo1/AddDemos';
import SongDetails from './module/submitSong/SongDetails';
import Appearance from './module/appearance/Appearance';
import AssociateArtistIndex from './module/AssociateArtist/AssociateArtistIndex';
import AssociateArtistDetails from './module/AssociateArtist/AssociateArtistDetails';
import AddAssociateArtist from './module/AssociateArtist/AddAssociateArtist';
import StudioAssociateList from './module/studioAssociate/StudioAssociateList';
import SnackbarContainer from './module/Snackbar';
import { logoutHandler } from './_services/auth/logout.service';
import AllPlaylist from './module/creater/songHub/playlist/AllPlaylist';
import PlaylistDetail from './module/creater/songHub/playlist/PlaylistDetail';
import PlaylistDrawer from './module/creater/songHub/playlist/PlaylistDrawer';
import DemoDetail from './module/submitDemo1/DemoDetails';
import EnquiriesDetail from './module/creater/enquiry/EnquiriesDetail';
import AllAlbumSong from './module/album/AllAlbumSong';
import AlbumDetail from './module/album/AlbumDetail';
import ConnectWithSpotify from './admin/connectWithSpotify';
import Royalty from './module/royalty/Royalty';
import Analytics from './module/Analytics/Analytics';
import Payout from './module/royalty/Payout';
import FAQs from './module/Faqs/FAQs'
import Support from './module/support/support'
import LoginPageFAQs from './module/Faqs/loginPageFAQ';
import Setting from './module/profile/setting';
import Discover from './module/creater/songHub/Discover/DiscoverList';
import OfficialPlaylist from './module/creater/songHub/Discover/OfficialPlaylist';
import Review from './module/creater/songHub/Discover/Review';
import SwipUserContent from './module/Swip/SwipUserContent';
import SwipUserGenre from './module/Swip/SwipUserGenre';
import OfficialPlaylistDetail from './module/creater/songHub/Discover/OfficialPlaylistDetail';
import MusicPlatforms from './module/creater/songHub/Discover/MusicPlatforms';
import Campaign from './module/Campaign/campaign';
import PromotionCampaign from './module/Campaign/promotionCampaign';
import CampaignDetail from './module/Campaign/campaignDetail';
import OTPVerification from './admin/otpVerification';
import RecommendationList from './module/Swip/RecommendationList';
import CollabrationNotificationList from './module/Swip/CollabrationNotificationList';
import { db, messaging } from './firebase';
import { getToken, onMessage } from 'firebase/messaging';
import { deleteDoc, doc } from "firebase/firestore";
import PhoneAuth from './admin/firebase_authentication/loginOtp';
import EmailPasswordFirebaseSignup from './admin/firebase_authentication/emailPasswordFirebaseSignup';
import ForgotPasswordFirebase from './admin/firebase_authentication/forgotPasswordFirebase';
import PaymentSuccess from './module/Campaign/paymentSuccess';

function useQuery() {
  return new URLSearchParams(window.location.search);
}
const theme = createTheme({
  palette: {
    secondary: {
      main: '#333'
    }
  },
});
function App() {
  let query = useQuery();
  const [callbackStatus, setCallbackStatus] = useState(query.get("callback"));
  const dispatch = useDispatch();
  const openBackdrop = useSelector((state) => state.loader.loader);
  let authenticationUrl = `${process.env.REACT_APP_AUTHENTICATION_SERVER}/logout?callback=${window.location.href}`;
  const userData = useSelector((state) => state.userData.userData);

  function loginStatus() {
    let status = localStorage.getItem("auth");
    if (status) {
      return true;
    }
    else {
      return false;
    }
  }

  // --------------- Service Worker code -----------------

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then(registration => {
          console.log('Service Worker registered successfully:', registration);
        })
        .catch(error => {
          console.error('Service Worker registration failed:', error);
        });
    }

    // Listen for foreground messages
    onMessage(messaging, (payload) => {
      console.log("Message received in foreground:", payload);
      // Show notification on screen
      new Notification(payload.notification.title, {
        body: payload.notification.body,
        icon: "/gntunes.png",
      });
    });

  }, [])

  // ------------------- remove fcmtoken logout


  // Call this function when user logs out
  const removeFCMToken = async () => {
    try {
      await deleteDoc(doc(db, "fcmTokens", userData.creator_auth));
      console.log("Token removed from Firestore.");
      localStorage.removeItem("fcmTokenSaved"); // Reset flag
    } catch (error) {
      console.error("Error removing token:", error);
    }
  };


  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Backdrop open={openBackdrop} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 500 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Switch>
          <PrivateRoute exact path="/orders" >
            <Order />
          </PrivateRoute>
          <PrivateRoute exact path="/helpdesk" >
            <Helpdesk />
          </PrivateRoute>
          <PrivateRoute exact path="/track-ticket" >
            <TrackTicket />
          </PrivateRoute>
          <PrivateRoute exact path="/issue" >
            <Issue />
          </PrivateRoute>
          <PrivateRoute exact path="/placement" >
            <Placement />
          </PrivateRoute>
          <PrivateRoute exact path="/artists">
            <Artists />
          </PrivateRoute>
          <PrivateRoute exact path="/shopping-cart">
            <ShoppingCart />
          </PrivateRoute>
          <PrivateRoute exact path="/services" >
            <Services />
          </PrivateRoute>
          <PrivateRoute exact path="/invoiceList" >
            <InvoiceList />
          </PrivateRoute>
          <PrivateRoute exact path="/invoices" >
            <Invoice />
          </PrivateRoute>
          <PrivateRoute exact path="/subscription" >
            <Subscription />
          </PrivateRoute>
          <PrivateRoute exact path="/profile" loginStatus={loginStatus}>
            <Profile />
          </PrivateRoute>
          <PrivateRoute exact path="/payment" >
            <ShoppingCart />
          </PrivateRoute>
          <PrivateRoute exact path="/enquires" >
            <Enquiries />
          </PrivateRoute>
          <PrivateRoute exact path="/enquiryDetail" >
            <EnquiriesDetail />
          </PrivateRoute>
          ------------------**********************---------------
          <PrivateRoute exact path="/card" >
            <Card />
          </PrivateRoute>
          {/* <PrivateRoute exact path="/createrDemo" >
          <Creator />
        </PrivateRoute> */}
          -------------------*********************-------------------
          <PrivateRoute exact path="/service-plan" >
            <ServicesPlan />
          </PrivateRoute>
          <PrivateRoute exact path="/dashboard" loginStatus={loginStatus}>
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute exact path="/mylinks" loginStatus={loginStatus}>
            <Creater />
          </PrivateRoute>
          <PrivateRoute exact path="/songhub" loginStatus={loginStatus}>
            <SongListComp />
          </PrivateRoute>
          <PrivateRoute exact path="/playlist" loginStatus={loginStatus}>
            <AllPlaylist />
          </PrivateRoute>
          <PrivateRoute exact path="/monetize-music" loginStatus={loginStatus}>
            <Discover />
          </PrivateRoute>
          <PrivateRoute exact path="/monetize-playlist" loginStatus={loginStatus}>
            <OfficialPlaylist />
          </PrivateRoute>

          <PrivateRoute exact path="/reviews" loginStatus={loginStatus}>
            <Review />
          </PrivateRoute>
          <PrivateRoute exact path="/collaboration/categories" loginStatus={loginStatus}>
            <SwipUserGenre />
          </PrivateRoute>
          <PrivateRoute exact path="/recommendations" loginStatus={loginStatus}>
            <RecommendationList />
          </PrivateRoute>
          <PrivateRoute exact path="/collaboration" loginStatus={loginStatus}>
            <SwipUserContent />
          </PrivateRoute>
          <PrivateRoute exact path="/collab-request" loginStatus={loginStatus}>
            <CollabrationNotificationList />
          </PrivateRoute>
          <PrivateRoute exact path="/artist-profile" loginStatus={loginStatus}>
            <SwipUserContent />
          </PrivateRoute>
          <PrivateRoute exact path="/appearance" loginStatus={loginStatus}>
            <Appearance />
          </PrivateRoute>
          <PrivateRoute exact path="/releases" loginStatus={loginStatus}>
            <SongList />
          </PrivateRoute>
          <PrivateRoute exact path="/releases/:songId/:albumId" loginStatus={loginStatus}>
            <SongDetails />
          </PrivateRoute>
          <PrivateRoute exact path="/submitdemo" loginStatus={loginStatus}>
            <DemoIndex />
          </PrivateRoute>

          <PrivateRoute exact path="/associatedartist" loginStatus={loginStatus}>
            <AssociateArtistIndex />
          </PrivateRoute>
          <PrivateRoute exact path="/associatedartist/:assoId" loginStatus={loginStatus}>
            <AddAssociateArtist />
          </PrivateRoute>
          <PrivateRoute exact path="/associateArtistDetails/:assoId" loginStatus={loginStatus}>
            <AssociateArtistDetails />
          </PrivateRoute>
          <PrivateRoute exact path="/playlistdetail/:playlistId" loginStatus={loginStatus}>
            <PlaylistDetail />
          </PrivateRoute>
          <PrivateRoute exact path="/playlist-detail" loginStatus={loginStatus}>
            <OfficialPlaylistDetail />
          </PrivateRoute>
          <PrivateRoute exact path="/music-detail" loginStatus={loginStatus}>
            <MusicPlatforms />
          </PrivateRoute>
          <PrivateRoute exact path="/playlistdrawer" loginStatus={loginStatus}>
            <PlaylistDrawer />
          </PrivateRoute>

          <PrivateRoute exact path="/addassociatedartist" loginStatus={loginStatus}>
            <AddAssociateArtist />
          </PrivateRoute>
          <PrivateRoute exact path="/studioassociatelist" loginStatus={loginStatus}>
            <StudioAssociateList />
          </PrivateRoute>

          <PrivateRoute exact path="/addreleasesong/:albumId" loginStatus={loginStatus}>
            <AddSubmitSong />
          </PrivateRoute>
          <PrivateRoute exact path="/addreleasesong/:songId/:albumId" loginStatus={loginStatus}>
            <AddSubmitSong />
          </PrivateRoute>

          <PrivateRoute exact path="/adddemo" loginStatus={loginStatus}>
            <AddDemos />
          </PrivateRoute>
          <PrivateRoute exact path="/adddemo/:demoId" loginStatus={loginStatus}>
            <AddDemos />
          </PrivateRoute>
          <PrivateRoute exact path="/submitdemo/:demoId" loginStatus={loginStatus}>
            <DemoDetail />
          </PrivateRoute>
          <PrivateRoute exact path="/releasealbum" loginStatus={loginStatus}>
            <AllAlbumSong />
          </PrivateRoute>
          <PrivateRoute exact path="/albumdetail/:albumId" loginStatus={loginStatus}>
            <AlbumDetail />
          </PrivateRoute>
          <PrivateRoute exact path="/" loginStatus={loginStatus}>
            <Profile />
          </PrivateRoute>

          <PrivateRoute exact path="/artist-verification" loginStatus={loginStatus}>
            <ArtistVerification />
          </PrivateRoute>
          <PrivateRoute exact path="/royalty" >
            <Royalty />
          </PrivateRoute>
          <PrivateRoute exact path="/analytics" >
            <Analytics />
          </PrivateRoute>
          <PrivateRoute exact path="/support" >
            <Support />
          </PrivateRoute>
          <PrivateRoute exact path="/payout" >
            <Payout />
          </PrivateRoute>

          <PrivateRoute exact path="/associatedartist" loginStatus={loginStatus}>
            <AssociateArtistIndex />
          </PrivateRoute>
          <PrivateRoute exact path="/associatedartist/:assoId" loginStatus={loginStatus}>
            <AddAssociateArtist />
          </PrivateRoute>
          <PrivateRoute exact path="/associateArtistDetails/:assoId" loginStatus={loginStatus}>
            <AssociateArtistDetails />
          </PrivateRoute>
          <PrivateRoute exact path="/playlistdetail/:playlistId" loginStatus={loginStatus}>
            <PlaylistDetail />
          </PrivateRoute>
          <PrivateRoute exact path="/playlistdrawer" loginStatus={loginStatus}>
            <PlaylistDrawer />
          </PrivateRoute>

          <PrivateRoute exact path="/addassociatedartist" loginStatus={loginStatus}>
            <AddAssociateArtist />
          </PrivateRoute>
          <PrivateRoute exact path="/studioassociatelist" loginStatus={loginStatus}>
            <StudioAssociateList />
          </PrivateRoute>

          <PrivateRoute exact path="/addreleasesong/:albumId" loginStatus={loginStatus}>
            <AddSubmitSong />
          </PrivateRoute>
          <PrivateRoute exact path="/addreleasesong/:songId/:albumId" loginStatus={loginStatus}>
            <AddSubmitSong />
          </PrivateRoute>

          <PrivateRoute exact path="/adddemo" loginStatus={loginStatus}>
            <AddDemos />
          </PrivateRoute>
          <PrivateRoute exact path="/adddemo/:demoId" loginStatus={loginStatus}>
            <AddDemos />
          </PrivateRoute>
          <PrivateRoute exact path="/submitdemo/:demoId" loginStatus={loginStatus}>
            <DemoDetail />
          </PrivateRoute>
          <PrivateRoute exact path="/releasealbum" loginStatus={loginStatus}>
            <AllAlbumSong />
          </PrivateRoute>
          <PrivateRoute exact path="/support" loginStatus={loginStatus}>
            <Support />
          </PrivateRoute>
          <PrivateRoute exact path="/albumdetail/:albumId" loginStatus={loginStatus}>
            <AlbumDetail />
          </PrivateRoute>
          <PrivateRoute exact path="/coverImage" loginStatus={loginStatus}>
            <Setting />
          </PrivateRoute>

          <PrivateRoute exact path="/artist-verification" loginStatus={loginStatus}>
            <ArtistVerification />
          </PrivateRoute>
          <PrivateRoute exact path="/royalty" >
            <Royalty />
          </PrivateRoute>
          <PrivateRoute exact path="/analytics" >
            <Analytics />
          </PrivateRoute>
          <PrivateRoute exact path="/payout" >
            <Payout />
          </PrivateRoute>
          <PrivateRoute exact path="/campaign" >
            <Campaign />
          </PrivateRoute>
          <Route exact path="/payment_success" >
            <PaymentSuccess />
          </Route>
          <PrivateRoute exact path="/campaign/create" >
            <PromotionCampaign />
          </PrivateRoute>
          <PrivateRoute exact path="/campaign/edit" >
            <PromotionCampaign />
          </PrivateRoute>
          <PrivateRoute exact path="/campaign/view" >
            <CampaignDetail />
          </PrivateRoute>
          {/* <PrivateRoute exact path="/razor-pay" >
            <RazorpayPayment />
          </PrivateRoute> */}
          <PrivateRoute exact path="/FAQs" >
            <FAQs />
          </PrivateRoute>
          <Route exact path="/login">
            <PhoneAuth />
          </Route>
          <Route exact path="/otp-verification">
            <OTPVerification />
          </Route>
          {/* <Route exact path="/user_auth/activate/:tokenKey/:tokenValue">
            <PhoneAuth />
          </Route> */}
          <Route exact path="/thank-you/">
            <SingupVerification />
          </Route>
          <Route exact path="/register">
            {/* <Register /> */}
            <EmailPasswordFirebaseSignup />
          </Route>
          <Route exact path="/registerUser">
            <RegisterUsers />
          </Route>
          <Route exact path="/sign-up">
            <SignUp />
          </Route>
          <Route exact path="/helpdesk">
            <Helpdesk />
          </Route>
          <Route exact path="/track-ticket">
            <TrackTicket />
          </Route>
          <Route exact path="/issue">
            <Issue />
          </Route>
          <Route exact path="/issue">
            <Issue />
          </Route>
          <Route exact path="/loginfaq">
            <LoginPageFAQs />
          </Route>
          <Route exact path="/forgot-password">
            <ForgotPasswordFirebase />
          </Route>
          <Route exact path="/connect-audio-platform">
            <ConnectWithSpotify />
          </Route>
          {/* <Route exact path="/change-password">
            <ChangePassword />
          </Route> */}
          {/* <Route exact path="/user_auth/passwordreset/:rowId/:token" >
            <ResetPassword />
          </Route> */}
          <Route path="/logout" render={() => {
            logoutHandler({})//called logout service
            removeFCMToken()
            removeUserToken();
            let callbackUrl = "/login";
            return <Redirect to={{ pathname: callbackUrl }} />;
          }
          }
          />
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </Router>
      <SnackbarContainer />
    </ThemeProvider>
  );
}

export default App;
