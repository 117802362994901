import { Box, Typography } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";


const CampaignHeader = ({ campaignType, activeStep }) => {
    const headers = {
        0: { head: "Select the perfect campaign to reach your audience effectively!", subhead: "" },
        1: { head: "Find Your Tracks", subhead: "" },
        2: {
            head: campaignType === "promotion"
                ? "Select platform where you want other artists to hear your song."
                : "Create Campaign",
            subhead: campaignType === "promotion"
                ? "Note - ensure that all the platform links are correct on your song micro-link."
                : "It’ll appear on your Campaign.",
        },
        3: {
            head: campaignType === "promotion"
                ? "How many people are you planning to include?"
                : "Choose Plan",
            subhead: campaignType === "promotion"
                ? "Keep in mind, the more people you invite, the higher the budget will be."
                : "The plan will appear on the basis of Genre, Mood & Language.",
        },
        4: { head: "Everything’s awesome", subhead: "" },
    };

    const { head, subhead } = headers[activeStep] || {};
    return (
        <Box mb={2}>
            <Typography variant="h6">{head}</Typography>
            {subhead && <Typography variant="subtitle1">{subhead}</Typography>}
        </Box>
    );
}
export default CampaignHeader;