import { Card, CardContent, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import * as React from 'react';
import { genreMoodLanguage } from '../../components/reusableComponents/genreMoodLanguage';
import { useState, useEffect } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLocation } from 'react-router-dom';
const minDate = new Date()
export default function ArtistCampaign({ stremingPlatform, songId, handleFormChange, formValues, releaseDateChangeHandler, formDateValues }) {
    const { getMoodList, getLanguageList, getGenreList } = genreMoodLanguage();
    const location = useLocation().state;
    const [genreList, setGenreList] = useState([]);
    const [moodList, setMoodList] = useState([]);
    const [languageList, setLanguageList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedMoods = await getMoodList();
                setMoodList(fetchedMoods);

                const fetchedLanguages = await getLanguageList();
                setLanguageList(fetchedLanguages);

                const fetchedGenres = await getGenreList();
                setGenreList(fetchedGenres);
            } catch (err) {
                // setError("Failed to fetch data");
                console.error("Error fetching data:", err);
            }
        };

        fetchData();

        return () => fetchData();
    }, []);

    return (
        <div>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                size='small'
                                className='w-100'
                                id="songId"
                                label="Song Name"
                                defaultValue={songId?.songTitle}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                size='small'
                                className='w-100'
                                label="Campaign Name"
                                name='campaignName'
                                value={formValues?.campaignName}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="genre-label">Select Genre</InputLabel>
                                <Select
                                    labelId="genre-label"
                                    id="genre-id"
                                    name='selectedGenres'
                                    value={formValues?.selectedGenres || ''}
                                    onChange={(e) => handleFormChange(e)}
                                    input={<OutlinedInput label="Select Genre" />}
                                >
                                    {genreList.length > 0 && genreList.map((genre) => (
                                        <MenuItem key={genre.id} value={genre.id}>
                                            <ListItemText primary={genre.genre} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="mood-label">Select Mood</InputLabel>
                                <Select
                                    labelId="mood-label"
                                    id="mood-id"
                                    value={formValues?.selectedMood || ''}
                                    name='selectedMood'
                                    onChange={(e) => handleFormChange(e)}
                                    input={<OutlinedInput label="Select Mood" />}
                                >
                                    {moodList && moodList.length > 0 && moodList.map((mood) => (
                                        <MenuItem key={mood.id} value={mood.id}>
                                            <ListItemText primary={mood.mood} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="language-label">Select Language</InputLabel>
                                <Select
                                    labelId="language-label"
                                    id="language"
                                    value={formValues?.selectedLanguage || ''}
                                    name='selectedLanguage'
                                    onChange={(e) => handleFormChange(e)}
                                    input={<OutlinedInput label="Select Language" />}
                                >
                                    {languageList && languageList.length > 0 && languageList.map((language) => (
                                        <MenuItem key={language.id} value={language.id}>
                                            <ListItemText primary={language.language} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="platform-label">Select Platform</InputLabel>
                                <Select
                                    labelId="platform-label"
                                    id="platform"
                                    multiple
                                    value={formValues?.selectedCampaignPlatform || ''}
                                    name="selectedCampaignPlatform"
                                    onChange={(e) => handleFormChange(e)}
                                    input={<OutlinedInput label="Select Platform" />}
                                    renderValue={(selected) => selected.map((value) => stremingPlatform.find(platform => platform.platformId === value).platformName).join(',')}
                                >
                                    {stremingPlatform &&
                                        stremingPlatform.map((platform) => (
                                            <MenuItem key={platform.platformId} value={platform.platformId}>
                                                <ListItemText primary={platform.platformName} />
                                            </MenuItem>
                                        ))}
                                </Select>

                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                size='small'
                                className='w-100'
                                label="Artist Acoounts to be Tagged"
                                value={formValues?.taggetAccount}
                                name='taggetAccount'
                                onChange={(e) => handleFormChange(e)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth={true} name="startDate">
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label='Start Date'
                                        inputFormat="YYYY-MM-DD"
                                        value={formDateValues?.startDate}
                                        name="startDate"
                                        minDate={minDate}
                                        onChange={(date) => releaseDateChangeHandler(date, 'startDate')}
                                        renderInput={(params) => <TextField size="small" sx={{ minWidth: "100%", mt: 1 }} {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth={true} name="endDate">
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label='End Date'
                                        inputFormat="YYYY-MM-DD"
                                        value={formDateValues?.endDate}
                                        name="endDate"
                                        minDate={formDateValues?.startDate}
                                        onChange={(date) => releaseDateChangeHandler(date, 'endDate')}
                                        renderInput={(params) => <TextField size="small" sx={{ minWidth: "100%", mt: 1 }} {...params} />}
                                        disabled={!formDateValues?.startDate}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                multiline
                                size='small'
                                className='w-100'
                                label="Suggested Hastag (4-5 tags)"
                                rows={5}
                                name='suggHashtag'
                                value={formValues?.suggHashtag}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                multiline
                                size='small'
                                className='w-100'
                                label="Special Notes"
                                name='specialNote'
                                rows={5}
                                value={formValues?.specialNote}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div >
    );
}
