import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import logoCreators from "../img/logoSmall2.png";
import logoGntunes from "../img/logoGntunes.svg";
import { RegisterUser } from "../_services/register";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { getUserType } from "../_services/userType/getUserType.service";
import { Link } from "react-router-dom";
import Footer from "./footer";
import { formSnackbar } from "../_services/_constants/snackbar.constant";
import { fetchUserTypeList, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from "../redux";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import SnackbarContainer from "../module/Snackbar";
import { Skeleton, TextField } from "@mui/material";
import './style.css'
import PhoneInput from "react-phone-input-2";
import backgroundImage from '../img/bg_image_texture3.png'
import spotify from '../img/streaming-platform-images/spotify.svg';


function Register(props) {
  const dispatch = useDispatch();
  const userTypeData = useSelector((state) => state?.userTypeReducer?.userTypeData)
  const [showConPassword, setShowConPassword] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [contactNumber, setContactNumber] = useState("")
  const [submit, setSubmit] = useState(false);
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [c_password, setC_Password] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passErr, setPassErr] = useState("");
  const [c_passErr, setC_PassErr] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [userType, setUserType] = useState("");
  const [userTypeId, setUserTypeId] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = React.useState(false);
  const [artistTypeError, setArtistTypeError] = useState(null)
  const LogoApp = process.env.REACT_APP_APPURL == 'https://creator.groovenexus.com' ? logoCreators : logoGntunes
  const handleClose = () => setOpen(false);
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  var regexp =
    /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!#$@^%&? "])(?=.*[@$!%*?&])[a-zA-Z0-9!#$@^%&?]{8,20}$/
  const phoneChangeHandler = (phone) => {
    if (phone.length < 12) {
      setPhoneError(
        <span className="text-danger small">
          Please fill the country code with phone number
        </span>
      );
      setContactNumber(phone)
    } else {
      setContactNumber(phone)
      setPhoneError('')
    }
  }
  const formInputChangeHandler = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
    }
    if (e.target.name === "name") {
      const inputValue = e.target.value.trim(); // Remove leading and trailing spaces
      const isValid = /^[a-zA-Z ]+$/.test(inputValue);
      if (isValid) {
        setName(inputValue);
        setNameError("");
      }
      else {
        setName("");
        setNameError(
          <span className="text-danger small">
            Please enter a valid name (only alphabets allowed)
          </span>
        );
      }
    }
    if (e.target.name === "contactNumber") {
      setContactNumber(e.target.value);
      setPhoneError("");
    }
    if (e.target.name === "password") {

      setPassword(e.target.value);
    }
    if (e.target.name === "c_password") {
      if (password === e.target.value) {
        setC_Password(e.target.value);
        setC_PassErr('')
      } else {
        setC_Password(e.target.value);
        setC_PassErr(
          <div className="form-group">
            <span className="text-danger small">
              Please enter valid confirm password
            </span>
          </div>
        );
      }
    }
    if (e.target.name === "userType") {
      document.getElementById(e.target.id).checked = true;
      setUserTypeId(e.target.id);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 3,
    boxShadow: 24,
    p: 3,
  };
  const submitClickHandler = (e) => {
    e.preventDefault();
    let postData = {};

    if (mailformat.test(email)) {
      postData.email = email;
      setEmailErr("");
    } else {
      setEmailErr(
        <div className="form-group">
          <span className="text-danger small">Please enter valid email</span>
        </div>
      );
    }
    if (name) {
      postData.name = name;
      setNameError("");
    } else {
      setNameError(
        <span className="text-danger small">
          Please fill the required field
        </span>
      );
    }
    if (contactNumber) {
      postData.contactNumber = contactNumber;
      setPhoneError("");
    }
    else {
      setPhoneError(
        <span className="text-danger small">
          Please fill the required field
        </span>
      );
    }
    if (userTypeId) {
      postData.userType = userTypeId;
      setArtistTypeError("");
    }
    else {
      setArtistTypeError(
        <div className="form-group">
          <span className="text-danger small">
            Please select your primary role.
          </span>
        </div>)
    }
    if (password.match(regexp)) {
      postData.password = password;
      setPassErr("");
    }
    else if (password.length === 0) {
      setPassErr(
        <div className="form-group">
          <span className="text-danger small">
            password is required{" "}
          </span>
        </div>
      );
    }
    else if (password.length < 8) {
      setPassErr(
        <div className="form-group">
          <span className="text-danger small">
            Your password must be at least 8 characters{" "}
          </span>
        </div>
      );
    }
    else if (password.search(/[a-z]/) < 0) {
      setPassErr(
        <div className="form-group">
          <span className="text-danger small">
            Your password must contain at least one lower case letter{" "}
          </span>
        </div>
      );
    }
    else if (password.search(/[A-Z]/) < 0) {
      setPassErr(
        <div className="form-group">
          <span className="text-danger small">
            Your password must contain at least one upper case letter{" "}
          </span>
        </div>
      );
    }
    else if (password.search(/[0-9]/) < 0) {
      setPassErr(
        <div className="form-group">
          <span className="text-danger small">
            Your password must contain at least one digit.{" "}
          </span>
        </div>
      );
    }
    else if (password.search(/[!@#\$%\^&\*_]/) < 0) {
      setPassErr(
        <div className="form-group">
          <span className="text-danger small">
            Your password must contain at least special char from -[ ! @ # $ % ^ & * _ ]{" "}
          </span>
        </div>
      );
    }
    else if (password.includes(" ")) {
      setPassErr(
        <div className="form-group">
          <span className="text-danger small">
            Password cannot contain white spaces.
          </span>
        </div>);
    }

    if (c_password === password) {
      postData.c_password = c_password;
      setC_PassErr("");
    } else {
      setC_PassErr(
        <div className="form-group">
          <span className="text-danger small">
            Please enter valid confirm password
          </span>
        </div>
      );
    }
    if (
      mailformat.test(email) &&
      c_password === password &&
      (password.match(regexp) && submit) && userTypeId && contactNumber
    ) {
      postData.policy = submit ? 1 : 0;
      dispatch(setLoader(true));
      dispatch(setSnackBarMessage(formSnackbar.PRE_REGISTER_MSG));
      dispatch(setSnackBarVariant('info'));
      dispatch(setSnackBar(true));
      RegisterUser(postData).then(function (response) {
        if (response.status === 200) {
          dispatch(setLoader(false));
          dispatch(setSnackBarMessage(formSnackbar.POST_REGISTER_MSG));
          dispatch(setSnackBarVariant('success'));
          setTimeout(() => dispatch(setSnackBar(false)), 2000);
          setTimeout(() => history.push("/thank-you"), 1500);
        }
        else {
          dispatch(setLoader(false));
          dispatch(setSnackBarVariant('error'));
          let responseData = response.data;
          if (responseData.email && responseData.email.length > 0) {
            dispatch(setSnackBarMessage(formSnackbar.errors.alreadyRegistered));
          }
          else {
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
          }
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
        }
      })
        .catch(function (error) {
          alert(error)
        })
    }
  };

  useEffect(function () {
    dispatch(fetchUserTypeList())
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickConfirmShowPassword = () => {
    setShowConPassword(!showConPassword);
  };

  return (
    <div className="container-fluid " style={{ height: '100vh', backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <div className="row justify-content-center">
        <nav className="navbar navbar-light  border pb-0">
          <div className="container-fluid">
            <a className="navbar-brand p-2" href="https://gntunes.com/">
              <img src={LogoApp} alt="" />
            </a>
          </div>
        </nav>
        <div className="text-center mt-4">
          <h1>Sign Up</h1>
        </div>
        <div className="col-md-9 col-lg-9 col-xl-6 col-sm-12 pb-5">
          <div className="login-form  mt-4 p-4 border rounded shadow bg-white" >
            <form action="" method="" className="row g-3 pb-4">
              <h5 className=" cardheading mb-3">
                Get Started With GN Tunes
              </h5>
              <div>
                <TextField
                  fullWidth
                  size="small"
                  id="outlined-basic"
                  label="Full Name *"
                  variant="outlined"
                  placeholder="Full Name"
                  type="text"
                  name="name"
                  className=""
                  onChange={formInputChangeHandler}
                />
                {nameError}
              </div>
              <div className="col-md-6">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Email *"
                  variant="outlined"
                  placeholder="Email Address"
                  type="text"
                  name="email"
                  className=" w-100"
                  onChange={formInputChangeHandler}
                />
                {emailErr}
              </div>

              <div className="col-md-6">
                <PhoneInput
                  inputProps={{
                    required: true
                  }}
                  className="addStrik"
                  country={'in'}
                  value={contactNumber}
                  name="contactNumber"
                  placeholder=""
                  onChange={phoneChangeHandler}
                />
                {phoneError}
              </div>
              <div className="col-md-6">
                <TextField
                  label="Password *"
                  size="small"
                  name="password"
                  placeholder="Password"
                  className=" w-100"
                  type={showPassword ? 'text' : 'password'}
                  variant="outlined"
                  value={password}
                  onChange={formInputChangeHandler}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />
                {passErr}
              </div>
              <div className="col-md-6">
                <TextField
                  label="Confirm Password *"
                  size="small"
                  name="c_password"
                  placeholder="Confirm Password"
                  className=" w-100"
                  type={showConPassword ? "text" : "password"}
                  variant="outlined"
                  value={c_password}
                  onChange={formInputChangeHandler}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleClickConfirmShowPassword}>
                        {showConPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />
                {c_passErr}
              </div>
              <div className="col-md-12 my-3">
                <div className="mb-2"><label>Your Primary Role *</label></div>
                {userTypeData ? (
                  userTypeData.map((data) => (
                    <div
                      className="btn-group col-md-4 col-sm-12 col-12 col-lg-4 text-white p-0 pb-2"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        name="userType"
                        id={data.id}
                        onChange={formInputChangeHandler}
                      />
                      <label
                        className="btn btn-outline-danger overflow-hidden text-truncate"
                        htmlFor={data.id}
                      >
                        <b>{data.userType}</b>
                      </label>
                    </div>
                  ))
                ) : (
                  <Skeleton height={60} animation="wave" />
                )}
                {artistTypeError}
              </div>
              {errorMsg}
              <div className="col-md-12 p-0 m-0">
                <ul className="">
                  <li className="small">Password must contain:</li>
                  <li className="small">
                    At least 8 characters, one special character, one numeric
                    value.{" "}
                  </li>
                  <li className="small">
                    {" "}
                    At least one uppercase letter, one lowercase letter .
                  </li>
                </ul>
              </div>

              <div className="col-md-12 m-0">
                <div className="form-check d-flex align-items-center">
                  <input
                    type="checkbox"
                    sx={{
                      color: "red !im",
                      "&.Mui-checked": {
                        color: "red",
                      },
                    }}
                    className="form-check-input  checkbox"
                    id="exampleCheck1"
                    onClick={(e) => setSubmit(prevvalue => !prevvalue)}
                  />
                  <label
                    className="form-check-label ps-2 mt-md-1 w-70"
                    htmlFor="exampleCheck1"
                  >
                    Yes, I understand and agree to gntunes Services
                  </label>
                </div>
              </div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <h6>Thanku for Register</h6>
                </Box>
              </Modal>
              <div className="xs-mb-2 fs-09 align-items-center align-items-lg-baseline align-items-md-center col-12 col-md-12 col-sm-12 d-flex d-lg-inline-block d-md-inline-block d-sm-flex flex-column flex-lg-row flex-md-column flex-sm-column">
                <span>
                  Already have account ?{" "}
                  <Link to="/" className="text-decoration-none text-primary ">
                    <strong>Sign In</strong>
                  </Link>
                  {""} or
                </span>
                <a className="text-decoration-none fw-bold text-primary" href="/connect-audio-platform"> Connect with audio platform</a>
                <button
                  type="submit"
                  className="gn-actionbtn float-end  mt-sm-3 mt-3 mt-lg-0 mt-md-0"
                  onClick={(e) => submitClickHandler(e)}
                  disabled={mailformat.test(email) && /^[a-zA-Z ]+$/.test(name) &&
                    c_password === password &&
                    (password.match(regexp) && submit) && userTypeId && contactNumber.length === 12 && submit ? false : true}
                >
                  SIGN UP
                </button>
                <SnackbarContainer />
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Register;
