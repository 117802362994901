import React, { useState } from "react";
import { setLoader } from "../../redux";
import { linkWithCredential, PhoneAuthProvider, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../firebase";
import { useDispatch } from "react-redux";
import { login } from "../../_services/auth/auth.service";
import { setSelfToken } from "../auth/secureToken";
import { useHistory } from 'react-router-dom';
import { RegisterUser } from "../../_services/register";

const useFirebaseAuthentication = ({ setConfirmationResult, confirmationResult, setReCAPTCHAToken, setErrorMsg, from, phoneNumber, otpCode, setTimer }) => {
    let history = useHistory();
    const dispatch = useDispatch();
    // State to hold the RecaptchaVerifier instance
    const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
    async function registerClickHandler(uid) {
        dispatch(setLoader(true));
        try {
            const response = await RegisterUser({ creator_auth: uid })
            if (response.status === 200) {
                dispatch(setLoader(false));
            }
            else {
                dispatch(setLoader(false));
            }
        }
        catch (error) {
            console.error("Error during login:", error);
        } finally {
            dispatch(setLoader(false));
        }
    }

    async function loginClickHandler(uid) {
        dispatch(setLoader(true));
        try {
            const response = await login({ username: uid });
            if (response.status === 200) {
                dispatch(setLoader(false));
                const output = response.data;
                if (output) {
                    setSelfToken(output.token);
                    history.replace(from.pathname);
                } else {
                    console.log(response);
                }
            }
            else {
                console.log(response.data.error);
                return response.data.error
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            dispatch(setLoader(false));
        }
    }


    const sendOTP = async () => {
        if (!phoneNumber || phoneNumber.length < 10) {
            setErrorMsg("Invalid phone number");
            return;
        }

        dispatch(setLoader(true));
        // Destroy the existing RecaptchaVerifier instance if it exists
        if (recaptchaVerifier) {
            recaptchaVerifier.clear(); // If using RecaptchaVerifier.clear() is still needed, just clear it.
        }
        try {
            const appVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                size: 'invisible',
                callback: (response) => {
                    console.log('reCAPTCHA solved:', response);
                    setReCAPTCHAToken(response)
                },
            });
            setRecaptchaVerifier(appVerifier); // Store the new RecaptchaVerifier instance

            const result = await signInWithPhoneNumber(auth, '+' + phoneNumber, appVerifier);
            dispatch(setLoader(false));
            setTimer(30);
            setConfirmationResult(result);
            console.log('OTP sent!');
        } catch (error) {
            dispatch(setLoader(false));
            setErrorMsg(error.message)
            console.error('Error sending OTP:', error.message);
        }
    };


    // ----------- verification otp --------------
    const verifyOTP = async () => {
        try {
            if (!confirmationResult) {
                console.error('No confirmation result found.');
                return;
            }

            const result = await confirmationResult.confirm(otpCode.join(""));
            // const uid = ""lpPdTPdBrFcAiUjw3Odil2taIHd2"";
            const uid = result.user.uid;
            // Attempt to login
            const loginResponse = await loginClickHandler(uid);
            console.log('OTP verified successfully!', loginResponse);

            if (loginResponse[0]) {
                console.warn("User not found. Attempting to register...");
                try {
                    await registerClickHandler(uid);
                    console.log("Registration successful. Logging in...");
                    await loginClickHandler(uid);
                } catch (registerError) {
                    console.error("Registration failed:", registerError);
                    setErrorMsg(registerError)
                }
            }
        } catch (otpError) {
            console.error('Error verifying OTP:', otpError.message);
            setErrorMsg(otpError.message)
        }
    };

    return { sendOTP, verifyOTP, loginClickHandler, registerClickHandler };

}
export default useFirebaseAuthentication;