import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useRazorpay from "react-razorpay";
import { useHistory } from "react-router-dom";

const useHandleRazorPayment = ({ packageAmount,songId }) => {
    const dispatch = useDispatch();
    const [Razorpay, isLoaded] = useRazorpay();
    const userData = useSelector((state) => state.userData.userData);

    // ------------------Payment gateway razor pay------------

    const handlePayment = React.useCallback(async () => {
        const formData = new FormData();
        formData.append("amount", packageAmount?.PackageAmount);
        formData.append("campaign_id", 'RC0122');
        formData.append("song_name", 'Teri Kami');
        try {
            const response = await fetch("https://muzicnetwork.com/api/auth/payment/", {
                method: "POST",
                headers: {
                    Authorization: `token af31564ad90e87fe7761644a4d9d070d4eca1ed1ec95121d881f90715c582239`,
                },
                body: formData,
            });

            const data = await response.json();

            if (!data?.razorpay_order_id) {
                throw new Error("Failed to create order");
            }

            const order = data?.razorpay_order_id;
            const callbackUrl = data?.callback_url;
            // Configure Razorpay options
            const options = {
                key: process.env.REACT_APP_RAZOR_PAY_KEY, // Test Key
                amount: packageAmount?.PackageAmount,
                currency: "INR",
                name: "GNTunes",
                description: "Paying for Campaign",
                order_id: order,
                callback_url: callbackUrl,
                redirect: true,
                allow_rotation: true,
                prefill: {
                    name: userData.name,
                    email: userData.email,
                    contact: userData.contactNumber,
                },
                notes: {
                    address: "Razorpay Corporate Office",
                },
                theme: {
                    color: "#C61414",
                },
                modal: {
                    ondismiss: function () {
                        console.log("Payment modal dismissed");
                    },
                },
            };

            const rzpay = new Razorpay(options); // Call Razorpay as a function
            rzpay.open();
        } catch (error) {
            console.error("Error processing payment:", error);
        }
    }, [Razorpay, isLoaded, packageAmount, userData, dispatch]);
    return handlePayment;
}

export default useHandleRazorPayment;
