import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import camera from '../../../img/general/camera.png';
import dactivecamera from '../../../img/general/dactivecamera.png';
import { useSelector } from 'react-redux';
import ApiService from '../../../_services/ApiService';
import CloseIcon from '@mui/icons-material/Close';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebase";
import useSnackDispatcher from '../../../_helpers/reusablefunctions/snackBarComponent';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteIcon from '@mui/icons-material/Delete';
import RecyclingIcon from '@mui/icons-material/Recycling';
import FirebaseImageStore from '../../../_helpers/reusablefunctions/firebaseImageStore';

const CollabPhoto = () => {
    const { infoSnack, successSnack, errorSnack } = useSnackDispatcher()
    const [selectedFilePreview, setSelectedFilePreview] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileId, setSelectedFileId] = useState(null);
    const userData = useSelector((state) => state.userData.userData);
    const { saveAvatar } = FirebaseImageStore({ selectedFile, functionCall: saveProfileAvatar })

    // ---------------- get user images--------------
    useEffect(() => {
        if (userData?.id) {
            ApiService.get('getcollabimage', { user: userData?.id }).then(response => {
                if (response.status === 200 || response.status === 201) {
                    if (response?.data?.profile_pictures?.length > 0) {
                        let arrayData = response.data.profile_pictures;
                        const updatedFiles = arrayData.sort(function (a, b) {
                            return parseInt(a.picture_order) - parseInt(b.picture_order);
                        });
                        setSelectedFilePreview(updatedFiles);
                    }
                }
            }).catch(console.error);
        }
    }, [userData]);

    // ----------------- file upload function
    const fileValidation = (e, index, imageId) => {
        const file = e.target.files[0];
        const maxFileSize = 100 * 1024 * 1024; // 100MB
        const validTypes = ["image/jpeg", "image/png", "image/jpg", "video/mp4", "video/wav"]; // Valid types (adjust as needed)
        const valueAfterSlash = file.type.split("/")[0];
        // Check file size
        if (file.size > maxFileSize) {
            errorSnack("File is too large. Max allowed size is 100MB.");
            return;
        }

        // Check file type
        if (!validTypes.includes(file.type)) {
            errorSnack("Invalid file type. Only images and videos are allowed.");
            return;
        }

        setSelectedFile(file);
        setSelectedFileId(imageId);
        setSelectedFilePreview(prev => {
            const updated = [...prev];
            updated[index] = { url: URL.createObjectURL(file), type: valueAfterSlash };
            return updated;
        });
    };

    // ----------------remove image api call---------------
    const removeMedia = (image) => {
        setSelectedFile(null);
        const getNewValue = selectedFilePreview.filter(d => d.url !== image.url)
        setSelectedFilePreview(getNewValue)
        if (image?.id) {
            ApiService.delete('getcollabimage', { user: userData.id }, image?.id)
                .then(res => {
                    if (res.status === 204 || res.status === 200) {
                        console.log("Image deleted successfully");
                    }
                })
                .catch(console.error);
        }
    };

    // ------------------ upload in firebase store and upload api call----------
    function saveProfileAvatar({ downloadURL, filePath }) {
        onSubmit(downloadURL, filePath)
    }
    useEffect(() => {
        if (selectedFile) {
            // onSubmit();
            saveAvatar()
        }
    }, [selectedFile]);

    const onSubmit = async (downloadURL, filePath) => {
        const type = selectedFile.type;
        const valueAfterSlash = type.split("/")[0];
        const payload = {
            profile_pictures: [{ user: userData?.id }, { url: downloadURL, path: filePath, type: valueAfterSlash }]
        };
        if (selectedFileId) {
            infoSnack("Image uploading...!");
            ApiService.put("getcollabimage", payload, null, selectedFileId)
                .then(() => {
                    successSnack(valueAfterSlash + " updated successfully!");
                })
                .catch((error) => {
                    console.error("API Error:", error);
                    errorSnack("Failed to update image!");
                });
        } else {
            ApiService.post("postcollabimage", payload)
                .then(() => {
                    successSnack(valueAfterSlash + " uploaded successfully!");
                })
                .catch((error) => {
                    console.error("API Error:", error);
                    errorSnack("Failed to upload image!");
                });
        }
    };

    // -------------------- drag and drop 

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from(selectedFilePreview);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setSelectedFilePreview(items);
        const data = items.map(item => item.id)
        let dataObj = {
            image_ids: data.join(',')
        }
        ApiService.put('sequencecollabimage', dataObj, { user: userData?.id }).then(res => {
            if (res.status == '200') {
            }
        }).catch(error => {
            // errorSnack(formSnackbar.errors.errorMsg)
            console.log(error)
        })
    }

    return (
        <Box>
            <Typography variant="h6">Upload your groove swipe photo & short videos</Typography>
            <Typography sx={{ color: "#8A8A8A" }}>
                Upload at least 1 photos & shorts.
            </Typography>
            <Grid container spacing={2} mt={1}>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters" direction="horizontal">
                        {(provided) => (
                            <Grid
                                container
                                display="flex"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {Array(5).fill('').map((_, index) => (
                                    <Draggable
                                        key={index}
                                        draggableId={index.toString()}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <Grid item xs={12} sm={4} md={3} lg={2.4} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <Box sx={{ position: "relative" }}>
                                                    {selectedFilePreview[index]?.url && (
                                                        <Box
                                                            sx={{
                                                                position: "absolute",
                                                                right: 10,
                                                                top: -10,
                                                                zIndex: 9,
                                                            }}
                                                        >
                                                            <IconButton
                                                                sx={{
                                                                    backgroundColor: "#ED1C24",
                                                                    borderRadius: "50%",
                                                                    mr: 1,
                                                                }}
                                                            >
                                                                <input
                                                                    type="file"
                                                                    onChange={(e) =>
                                                                        fileValidation(e, index, selectedFilePreview[index]?.id)
                                                                    }
                                                                    style={{
                                                                        position: "absolute",
                                                                        height: "100%",
                                                                        width: "100%",
                                                                        opacity: 0,
                                                                        cursor: "pointer",
                                                                    }}
                                                                    title={selectedFilePreview[index]?.url}
                                                                />
                                                                <RecyclingIcon sx={{ color: "#fff" }} fontSize="small" />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => removeMedia(selectedFilePreview[index])}
                                                                sx={{
                                                                    backgroundColor: "#ED1C24",
                                                                    borderRadius: "50%",
                                                                }}
                                                            >
                                                                <DeleteIcon sx={{ color: "#fff" }} fontSize="small" />
                                                            </IconButton>
                                                        </Box>
                                                    )}
                                                    <Box
                                                        sx={{
                                                            border: "2px dashed",
                                                            borderColor:
                                                                index > 0 && !selectedFilePreview[index - 1]?.url
                                                                    ? "#8A8A8A"
                                                                    : "#ED1C24",
                                                            padding: "10px",
                                                            margin: "5px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            height: "250px",
                                                            borderRadius: "10px",
                                                            position: "relative",
                                                            overflow: "hidden",
                                                        }}
                                                    >
                                                        {!selectedFilePreview[index]?.url && (
                                                            <input
                                                                type="file"
                                                                onChange={(e) => fileValidation(e, index)}
                                                                disabled={index > 0 && !selectedFilePreview[index - 1]?.url}
                                                                style={{
                                                                    position: "absolute",
                                                                    height: "100%",
                                                                    width: "100%",
                                                                    opacity: 0,
                                                                    cursor: "pointer",
                                                                    zIndex: 1
                                                                }}
                                                            />
                                                        )}
                                                        {selectedFilePreview[index]?.url ? (
                                                            selectedFilePreview[index]?.type === "image" ? (
                                                                <img
                                                                    src={selectedFilePreview[index]?.url}
                                                                    alt="Selected"
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        objectFit: "cover",
                                                                        borderRadius: "10px",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <video
                                                                    controls
                                                                    src={selectedFilePreview[index]?.url}
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        borderRadius: "10px",
                                                                    }}
                                                                />
                                                            )
                                                        ) : (
                                                            <IconButton
                                                                sx={{
                                                                    backgroundColor:
                                                                        index > 0 && !selectedFilePreview[index - 1]?.url
                                                                            ? "transparent"
                                                                            : "#ED1C24",
                                                                    borderRadius: "50%",
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        index > 0 && !selectedFilePreview[index - 1]?.url
                                                                            ? dactivecamera
                                                                            : camera
                                                                    }
                                                                    alt="Upload Icon"
                                                                />
                                                            </IconButton>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Grid>
                        )}
                    </Droppable>
                </DragDropContext>
            </Grid>
        </Box >
    );
};

export default CollabPhoto;
