import React, { useEffect, useState } from 'react';
import {
    Avatar, Box, Button, Tab, Tabs, Typography, Rating, Divider, styled, Chip, Stack,
} from '@mui/material';
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';
import { useSelector } from 'react-redux';
import ApiService from '../../_services/ApiService';
import SwipUserContent from './SwipUserContent';
import { CardSkeletonCollab } from '../../components/reusableComponents/SkeletonCard';
import NoResults from '../../components/reusableComponents/NoResults';
import profileImge from '../../img/profileImage.png'
import { getDateAsPerFormat, getTime } from '../../_helpers/reusablefunctions/dateSetter';
import { useHistory } from 'react-router-dom';

const filterSongs = [
    { status: '', label: 'getCollabAcceptedRequest', value: ["Accepted"] },
    { status: 'Pending', label: 'getCollabRequest', value: ["Pending"] }
]
const numberOfSkeletonCards = 1
const skeletonCards = Array.from({ length: numberOfSkeletonCards }, (_, index) => (
    <CardSkeletonCollab key={index} />
));
const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: '10px',
    color: '#545454',
    background: '#fff',
    border: '0px',
    padding: '6px 20px 6px 20px',
    fontSize: '14px',
    flex: 'wrap',
    gap: 2,
    '&:hover': {
        backgroundColor: '#ED1C24',
        color: '#fff',
    },
    '&.MuiChip-clickable:focus, &.MuiChip-clickable:hover': {
        backgroundColor: '#ED1C24',
        color: '#fff',
    },
    '&.MuiChip-clickable': {
        '&.Mui-active': {
            backgroundColor: '#ED1C24',
            color: '#fff',
        },
    },
}));
const CollabrationNotificationList = () => {
    const { errorSnack, infoSnack, successSnack } = useSnackDispatcher();
    const userData = useSelector((state) => state.userData.userData);
    const [collabRequest, setCollabRequest] = useState([]);
    const [activeChip, setActiveChip] = useState('0-0');
    const [activeStatus, setActiveStatus] = useState('');
    const [activeLabel, setActiveLabel] = useState('getCollabAcceptedRequest');
    const [skeletonLoader, setSkeletonLoader] = useState(true);
    let history = useHistory();

    // -------------- get recommendation list 

    function collabRequestApicall(endPoint) {
        setSkeletonLoader(true)
        setCollabRequest([])
        ApiService.get(endPoint, { status: activeStatus }, userData?.id + '/').then((res) => {
            if (res?.status == 200 || res?.status == 201) {
                setCollabRequest(res?.data?.results)
                setSkeletonLoader(false)
            }
        })
            .catch(error => {
                console.log(error)
                errorSnack(error)
                setSkeletonLoader(false)
            })
    }

    useEffect(() => {
        if (userData?.id) {
            collabRequestApicall(activeLabel)
        }
    }, [userData, activeStatus, activeLabel])

    // ------------ chip change 

    const handleChipClick = (i, data) => {
        setActiveChip(i)
        setActiveStatus(data?.status)
        setActiveLabel(data?.label)
    }

    // --------------- status change
    const statusChangeHandler = (data, status) => {

        let payload = {
            sender_id: data?.sender?.id,
            status: status
        }
        infoSnack(status + " Request")
        ApiService.put('postCollabRequest', payload, null, userData?.id).then((res) => {
            if (res.status == 201 || res.status == 200) {
                successSnack(status + " Request")
                collabRequestApicall('getCollabRequest')
            }
            if (res.status == 500) {
                errorSnack("Request Failed.")
            }
        })
            .catch(error => {
                errorSnack(error)
                console.log(error)
            })
    }

    const userProfileOpen = (data) => {
        history.push('/artist-profile', { data: data, status: activeStatus, page: 'artist' })
    }

    return (
        <Box className='container-fluid py-4 px-4 creator-playlist-panel position-relative'>
            <h2>Collaboration-Requests</h2>
            <Stack direction="row" spacing={1}>
                {filterSongs.map((d, i) =>
                    d.value.map((v, index) => (
                        <StyledChip
                            key={`${i}-${index}`}
                            label={v}
                            component="a"
                            variant="outlined"
                            clickable
                            onClick={() => handleChipClick(`${i}-${index}`, d, v)}
                            className={activeChip === `${i}-${index}` ? 'Mui-active' : ''}
                        />
                    ))
                )}
            </Stack>

            {/* collab list */}

            <Box mt={3} sx={{ padding: 3, backgroundColor: '#fff' }}>
                {skeletonLoader ?
                    <div className="wrapper-card">
                        {skeletonCards}
                    </div> :
                    collabRequest.length > 0 ?
                        <Box>
                            {
                                collabRequest.map((data, index) => (
                                    <Box key={index} mb={3}>
                                        {/* User Info */}
                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                            <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }} onClick={() => userProfileOpen(data)} >
                                                <Avatar
                                                    // src={data?.sender?.avatar_url ? data?.sender?.avatar_url : profileImge}
                                                    src={data?.avatar_url ? data?.avatar_url : data?.sender?.avatar_url ? data?.sender?.avatar_url : profileImge}
                                                    alt={data?.sender?.name}
                                                    sx={{ marginRight: 2 }}
                                                />
                                                <Box>
                                                    <Typography variant="body1" fontWeight="bold">
                                                        {data?.sender?.name || data?.name || '-'}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {data?.sender?.primary_category || data?.primary_category || '-'}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>

                                        {/* Feedback Content */}
                                        <Typography variant="body2" color="textSecondary" mt={1}>
                                            {data?.sender?.about || data?.about || '-'}
                                        </Typography>

                                        {/* Action Buttons */}
                                        <Box display="flex" justifyContent={'space-between'} alignItems={'baseline'} gap={2} mt={2}>
                                            <Typography variant="body2" color="textSecondary" display="block">
                                                {data?.created_at && getDateAsPerFormat(data?.created_at, "dd/MM/yyyy") + " " + getTime(data?.created_at)}
                                            </Typography>
                                            {activeStatus == 'Pending' ? <Box>
                                                <Button variant="outlined" onClick={() => statusChangeHandler(data, 'Rejected')}
                                                    className='gn-actionbtn-outline gn-actionbtn-color px-3'>
                                                    Reject
                                                </Button>
                                                <Button variant="contained" onClick={() => statusChangeHandler(data, 'Accepted')}
                                                    className='gn-actionbtn px-3 ms-3'>
                                                    Accept
                                                </Button>
                                            </Box> : null}
                                        </Box>

                                        <Divider sx={{ marginY: 2 }} />
                                    </Box>
                                ))
                            }
                        </Box>
                        :
                        <NoResults />
                }
            </Box>
        </Box >
    );
};

export default CollabrationNotificationList;
